@import './variables.scss';

.mall_common__footer {
    background-color: #fff;

    a {
        color: inherit;
        text-decoration: none;
        line-height: 1em;
    }
}
.mall_common__footer_grey {
    background-color: #f5f5f5;
}
.mall_common__footer_container {
    width: $web_width;
    margin-left: auto;
    margin-right: auto;
}
.mall_common__footer__feature_list {
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 22px;
    color: #666;

    & > li {
        display: inline-block;
        width: 25%;
        padding-top: 30px;
        padding-bottom: 30px;
        box-sizing: border-box;
        text-align: center;

        & > img {
            display: inline-block;
            width: 40px;
            height: 40px;
            margin-right: 10px;
            background: linear-gradient(#f67b06 0%, #ff3f00 100%);
            border: none;
            outline: none;
            vertical-align: middle;
            border-radius: 50%;
        }

        & > div {
            display: inline-block;
            vertical-align: middle;
        }
    }
}
.mall_common__footer__copyright {
    font-size: 12px;
    color: #666;
    box-shadow: 0px -1px 0px 0px #eee;
    padding-top: 20px;
    padding-bottom: 53px;
    line-height: 1.8em;
}
.mall_common__footer__links {
    font-size: 12px;
    color: #666;
    padding-top: 22px;
    padding-bottom: 22px;
    box-shadow: 0px -1px 0px 0px #eee;

    & > p {
        line-height: 1em;
    }

    a {
        display: inline-block;
        color: inherit;
        text-decoration: none;
        padding-left: 5px;
        padding-right: 5px;
        margin-top: 6px;
        margin-bottom: 6px;
        line-height: 1em;

        & + a {
            border-left: 1px solid #666;
        }
    }
}
.mall_common__footer__links_title {
    display: inline-block;
    vertical-align: middle;
}
.mall_common__footer__links_content {
    display: inline-block;
    vertical-align: middle;
}
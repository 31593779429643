@import './variables.scss';

.mall_common__category {
    position: relative;
    display: inline-block;
    width: 230px;
    margin-right: 30px;

    cursor: pointer;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &:hover .mall_common__category_nav {
        display: block;
    }
}

.mall_common__category_nav {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: $max_zindex;

    display: none;
    width: 100%;
}

.mall_common__category_title {
    padding: 10px 10px 12px 30px;

    background-color: #ff6f00;
    color: #fff;
    font-size: 16px;
}

.mall_common__category_first_list {
    max-height: 500px;
    overflow-y: auto;
    padding-top: 5px !important;
    box-sizing: content-box;
    background: #3e424c;

    & > li {
        padding: 17px 30px;

        font-size: 16px;
        background-color: #3e424c;
        color: #fff;
    }
}
.mall_common__category_first_list_item_icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 11px;
    vertical-align: -4px;

    img {
        display: block;
        width: 100%;
        height: 100%;
    }
}
.mall_common__category_first_list_item_next {
    float: right;
    width: 14px;
    height: 14px;
    margin-top: 5px;

    background-image: url("./images/arrow_right_default.png");
    background-repeat: no-repeat;
    background-size: contain;
}
li.mall_common__category_first_list_item_active {
    color: #ff6f00;
    background: #fff;

    .mall_common__category_first_list_item_next {
        background-image: url("./images/arrow_right_actived.png");
    }
}

.mall_common__category_second {
    position: absolute;
    left: 100%;
    top: 4px;

    display: none;
    width: 600px;
    min-height: 501px;
    padding-top: 15px;

    background-color: #fff;
    font-size: 14px;
    box-sizing: border-box;
    box-shadow: 0 1px 4px #0000001f;
}
.mall_common__category_second_list {
    & > li {
        margin-bottom: 15px;
    }
}
.mall_common__category_second_list_item_title {
    display: inline-block;
    width: 20%;
    padding-left: 30px;
    padding-right: 5px;
    padding-top: 5px;
    box-sizing: border-box;

    color: #333;
    font-weight: 500;
    vertical-align: top;
    text-align-last: justify;
}
.mall_common__category_third_list {
    display: inline-block;
    width: 80%;
    padding-right: 10px;
    box-sizing: border-box;

    & > li {
        display: inline-block;
        padding: 5px 10px;

        color: #666;
    }
}
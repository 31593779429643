$cartcolor: #FF4500;
.full-white {
    width: 100%;
    overflow: hidden;
    background: #fff;
}
.w {
    width: 1180px;
    margin: 0 auto;
}
.cart-main {
    .cart_list_err {
        padding: 0 0 100px;
        img {
            display: block;
            width: 180px;
            height: 175px;
            margin: 50px auto 0;
        }
        button {
            cursor: pointer;
            display: block;
            width:100px;
            height:30px;
            background:rgba(255,69,0,1);
            border-radius:4px;
            line-height: 30px;
            text-align: center;
            color: #fff;
            font-size: 16px;
            font-weight: bold;
            margin: 20px auto;
        }
    }
    .tb_con {
        width: 100%;
        border-bottom: 3px solid #eee;
    }
    .tab_item {
        cursor: pointer;
        margin-bottom: -3px;
        float: left;
        width: 120px;
        height: 46px;
        line-height: 46px;
        font-size: 14px;
        font-weight: 450;
        border-bottom: 3px solid #eee;
        text-align: center;
        span {
            color: $cartcolor;
        }
        &.on {
            color: $cartcolor;
            border-bottom: 3px solid $cartcolor;
        }
    }
    .invalid_list {
        .cart_item_box {
            background: #ECECEC;
            border: 1px solid #f1f1f1;
        }
        .invalid_line {
            border: 1px solid #ececec;
            padding: 10px 8px;
            background: #fff;
            .icons-invalid {
                vertical-align: 1px;
            }
            span {
                line-height: 30px;
            }
            .empty_btn {
                float: right;
                cursor: pointer;
                border: 1px solid #DEDEDE;
                width: 120px;
                height: 30px;
                line-height: 30px;
                border-radius: 15px;
                text-align: center;
                color: #666;
                font-size: 14px;
            }
        }
    }
    .store_line {
        height: 50px;
        border: 1px solid #ECECEC;
        background: #fcfcfc;
        padding-left: 10px;
        line-height: 50px;
        .store_name {
            float: left;
            color: #333;
            font-size: 14px;
            margin-left: 10px;
            line-height: 48px;
        }
    }
    .cart_item_box {
        // height: 120px;
        min-height: 120px;
        padding: 20px 40px 20px 10px;
        border: 1px solid #ececec;
        border-top: none;
        .img_box {
            margin-left: 11px;
            width: 80px;
            height: 80px;
            border-radius: 6px;
            margin-right: 6px;
            position: relative;
            cursor: pointer;
            img {
                width: 80px;
                height: 80px;
            }
        }
        .mask {
            left: 10px;
            top: 10px;
            width: 60px;
            height: 60px;
            border-radius: 30px;
            transform: rotate(-20deg);
            transform-origin: 50% 50%;
            background: rgba(0,0,0,0.6);
            line-height: 60px;
            &.low_l {
                top: 60px;
                bottom: 0;
                left: 0;
                right: 0;
                background: rgba(0,0,0,0.6);
                border-radius: 0;
                transform: rotate(0deg);
                width: 100%;
                height: 20px;
                line-height: 20px;
                font-size: 12px;
            }
        }
        .item_check {
            // position: relative;
            // top: 50%;
            // transform: translateY(-50%);
            margin-top: 30px;
        }
        .item_name {
            margin-top: 10px;
            width: 310px;
            padding-right: 50px;
            padding-left: 10px;
            cursor: pointer;
        }
        .spec {
            width: 190px;
            font-size: 14px;
            color: #999;
            p {
                margin-top: 8px;
            }
        }
        .price, .priceTotal {
            margin-top: 5px;
            width: 180px;
            text-align: center;
            font-size: 16px;
            color: #333;
            font-weight: bold;
        }
        .priceTotal {
            color: $cartcolor;
        }
        .operate {
            margin-top: 6px;
            font-size: 14px;
            color: #333;
            cursor: pointer;
        }
        &.checked {
            background: #FFF9E7;
        }
    }
    .cart_bottom_bar {
        margin-top: -1px;
        height: 80px;
        background: #fff;
        border: 1px solid #ececec;
        padding: 0 15px 0 10px;
        line-height: 80px;
        .delete_text {
            float: left;
            margin-left: 20px;
            cursor: pointer;
        }
        .choosen_info {
            margin-top: 16px;
            float: left;
            line-height: 24px;
            font-size: 14px;
            color: #333;
            span {
                margin: 0 3px;
                font-weight: bold;
                color: $cartcolor
            }
        }
        .price_box {
            float: left;
            height: 80px;
            margin-left: 35px;
            margin-right: 40px;
            position: relative;
            overflow: hidden;
            .price_area {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
                line-height: 24px;
                font-size: 14px;
                color: #333;
                text-align: right;
                .money {
                    span {
                        font-size: 14px;
                    }
                    font-size: 18px;
                    color:$cartcolor;
                    font-weight: bold;
                }
                .promo {
                    line-height: 22px;
                }
            }
        }
       
        .checkout_btn {
            float: right;
            margin-top: 15px;
            width: 180px;
            height: 50px;
            border-radius: 4px;
            background: $cartcolor;
            color: #fff;
            font-size: 22px;
            line-height: 50px;
            text-align: center;
            font-weight: bold;
            cursor: pointer;
        }
    }
}
// .cart-main input {
//     // appearance: checkbox;
// }
.cart-main .cart-thead {
    display: inline-block;
    display: block;
    height: 44px;
    line-height: 44px;
    margin: 0 0 10px;
    // background: #f3f3f3;
    // border: 1px solid #e9e9e9;
    border-top: 0;
    position: relative
}
.cart-main .yscheckbox {
    float: none;
    position: relative;
    z-index: 5;
    vertical-align: middle;
    _vertical-align: -1px;
    margin: 0 3px 0 0;
    padding: 0;
}

.cart-main .cart-thead:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden
}

.cart-main .cart-column {
    float: left
}

.cart-main .cart-thead .t-checkbox {
    height: 44px;
    line-height: 44px;
    width: 133px;
    padding-left: 11px
}

.cart-main .cart-thead .t-goods {
    width: 300px
}

.cart-main .cart-thead .t-props {
    width: 190px;
    height: 32px;
}

.cart-main .cart-thead .t-price {
    width: 180px;
    text-align: center;
}

.cart-main .cart-thead .t-quantity {
    width: 114px;
    text-align: center
}

.cart-main .cart-thead .t-sum {
    width: 180px;
    text-align: center;
}

.cart-main .cart-thead .t-action {
    width: 75px
}
.checkout_pop {
    width: 480px;
    border-radius: 2px;
    .app-popup-container-scale {
        padding-top: 0;
        border-radius: 2px;
    }
    .cart_accountpop {
        .title {
            padding: 0 30px 0 20px;
            height: 56px;
            font-weight: 500;
            border-bottom: 1px solid #EEE;
            p {
                float: left;
                line-height: 56px;
                font-size: 16px;
                color: #000;
            }
            i {
                display: block;
                float: right;
                cursor: pointer;
                font-size: 18px;
                line-height: 56px;
            }
        }
        .list {
            padding: 0 20px;
            li {
                position: relative;
                border-bottom: 1px solid #ececec;
                &:last-child {
                    border-bottom: 0;
                }
                .items_info {
                    width: 340px;
                }
                .sub_line {
                    margin-top: 8px;
                    font-size: 14px;
                    span {
                        color: $cartcolor;
                    }
                }
                button {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    cursor: pointer;
                    float: right;
                    width: 75px;
                    height: 32px;
                    text-align: center;
                    border-radius:2px;
                    line-height: 32px;
                    font-size: 14px;
                    background: #FF6F00;
                    color: #fff;
                }
            }
        }
    }
}
.patient-modal {
    width: 650px;
    .modal_head {
        padding: 0 20px;
        border-bottom: 1px dashed #e0e0e0;
        height: 45px;
        line-height: 35px;
        p {
            font-size: 16px;
            font-weight: bold;
            color: #333;
            margin-right: 10px;
        }
        span {
            color: #999;
            font-size: 14px;
        }
        i {
            cursor: pointer;
            font-size: 20px;
        }
    }
    .modal_bottom {
        height: 52px;
        border-top: 1px solid #eee;
        padding: 10px 20px;
        button {
            height: 32px;
            float: right;
            border-radius: 2px;
            margin-left: 15px;
            font-size: 14px;
            padding: 0 15px;
            cursor: pointer;
            &.confirm {
                background: #FF6F00;
                color: #fff;
            }
            &.cancel {
                border:1px solid #e1e1e1;
            }
        }
    }
}
.patient-main {
    margin-top: 10px;
    padding: 0 20px;
    ::-webkit-scrollbar{
        width: 5px;
        height: 5px;
    }
    ::-webkit-scrollbar-thumb{
        border-radius: 1em;
        background-color: rgba(50,50,50,.3);
    }
    ::-webkit-scrollbar-track{
        border-radius: 1em;
        background-color: rgba(50,50,50,.1);
    }
    .patient_list {
        .full_info {
            width: 560px;
            height: 40px;
            background: #FFF9E7;
            border: 1px solid #ececec;
            border-radius: 4px;
            line-height: 40px;
            color: #666;
            font-size: 14px;
            i {
                color: $cartcolor;
                margin-left: 18px;
            }
        }
        .add_patient {
            cursor: pointer;
            &:hover {
                color: $cartcolor;
            }
        }
        .btn_show_all {
            color: $cartcolor;
            cursor: pointer;
        }
        .list {
            width: 570px;
            height: 80px;
            &.showMore {
                height: 140px;
                width: 575px;
                overflow-y: scroll;
                // box-shadow:inset 0px -40px 40px -40px #eee;
            }
            .patient {
                width: 180px;
                height: 70px;
                border-radius: 4px;
                border: 1px solid #ccc;
                float: left;
                margin: 10px 10px 0 0;
                cursor: pointer;
                padding: 5px 0 5px 15px;
                position: relative;
                p {
                    line-height: 30px;
                }
                &.selected {
                    background:rgba(255,249,231,1);
                    border: 1px solid $cartcolor;
                }
                .img_selected {
                    position: absolute;
                    bottom: -1px;
                    right: -1px;
                    width: 40px;
                    height: 40px;
                }
            }
        }
        
    }
}
.as__refund_item {
    padding: 15px 24px 15px 12px;
    font-size: 12px;
    color: #333;

    p {
        margin: 0;
    }

    .col {
        display: inline-block;
        vertical-align: middle;
    }

    .image {
        display: inline-block;
        vertical-align: top;
        width: 60px;
        height: 60px;
        margin-right: 10px;

        img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    .title {
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    .spec {
        color: #999;
    }

    .price {
        margin-right: 15px;
        color: #ff4500;
        font-weight: bold;
        display: inline-block;
        vertical-align: top;
    }

    .count {
        float: right;
    }
}

.usercenter-wrapper { overflow: hidden; }
.usercenter-nav {
    width: 180px; padding: 30px 0 0 30px; color: #666; background: #fff; float: left; margin-bottom: -99999px; padding-bottom: 99999px;
    section { padding-bottom: 40px; }
    h1 { font-size: 16px; font-weight: bold; }
    button {
        display: block; margin-top: 10px; font-size: 14px; cursor: pointer;
        &:hover { opacity: .7; }
        &.active { color: #ff6f00; }
    }
}
.usercenter-title {
    position: relative; height: 32px; line-height: 32px;
    h1 { float: left; font-size: 20px; font-weight: bold; color: #666; }
    .desc { margin-left: 10px; float: left; font-size: 14px; font-weight: 400; color: #999; }
    button { position: absolute; bottom: 0; right: 0; width: 120px; height: 32px; border: 1px solid #ff6f00; color: #ff6f00; font-size: 14px; top: 0px; cursor: pointer;}
}
.usercenter-title-seperator {
    position: relative; margin-top: 10px; margin-bottom: 20px;
    .orange {
        position: absolute; left: 0; width: 78px; height: 7px; border-bottom: 2px solid #ff6f00;
        .triangle { position: absolute; left: 34px; bottom: -5px; width: 10px; height: 10px; border-top: 2px solid #ff6f00; border-left: 2px solid #ff6f00; background: white; transform: rotate(45deg); }
    }
    .line { margin-left: 78px; height: 7px; border-bottom: 2px solid #ececec; }
}
.usercenter-content-wrapper { margin-left: 200px; background-color: #fff; width: 980px; margin-bottom: -99999px; padding-bottom: 99999px; }
.usercenter-content { padding: 40px 60px 40px 60px; }
.usercenter-breadcrumb {
    position: relative; display: inline-block; margin-right: 50px; font-size: 18px; color: #ff6f00; vertical-align: top; line-height: 40px;

    .triangle { position: absolute; left: 8px; width: 20px; height: 20px; border-top: 2px solid #f5f5f5; border-left: 2px solid #f5f5f5;background: #f5f5f5; transform: rotate(45deg); top: 100%; margin-top: 23px; }
}
.app-popup-container, .app-popup-mask {
  position: fixed;
}
.cm_checkbox__inner {
  display: block;
  position: relative;
  border: 1px solid #979797;
  border-radius: 2px;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  background-color: #fff;
  z-index: 1;
  transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
    background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);

  &:before {
    box-sizing: content-box;
    content: "";
    border: 0;
    width: 32px;
    height: 32px;
    left: 50%;
    top: 50%;
    margin: -16px 0 0 -16px;
    position: absolute;
  }

  &:after {
    box-sizing: content-box;
    content: "";
    border: 1px solid #fff;
    border-left: 0;
    border-top: 0;
    height: 7px;
    position: absolute;
    left: 7px;
    top: 1px;
    -ms-transform: rotate(45deg) scaleY(0);
    transform: rotate(45deg) scaleY(0);
    width: 4px;
    transition: transform 0.15s cubic-bezier(0.71, -0.46, 0.88, 0.6) 0.05s;
    -ms-transform-origin: 0 2px;
    transform-origin: 0 2px;
  }

  &.is_checked {
    background-color: #ff6f00;
    border-color: #ff6f00;
    &:after {
      transform: rotate(45deg) scaleY(1);
    }
  }

  &.is_disabled {
    background-color: #ddd;
    border-color: #ddd;
    cursor: not-allowed;
    &:after {
      border-color: #ddd;
    }
  }
}

.cm_checkbox {
  display: inline-block;
  position: relative;

  .cm_checkbox_container,
  .cm_checkbox__input,
  .cm_checkbox__label {
    pointer-events: none;
  }

  .cm_checkbox__input {
    display: inline-block;
    outline: 0;
    position: absolute;
    vertical-align: middle;
    top: 50%;
    transform: translateY(-50%);
  }

  .cm_checkbox__original {
    opacity: 0;
    outline: 0;
    position: absolute;
    margin: 0;
    width: 0;
    height: 0;
    left: -999px;
  }

  .cm_checkbox__label {
    padding-left: 21px;
    float: left;
  }
}

.cm_num__modifier {
  height: 32px;
}
.cm_num__modifier button {
  background: rgb(255, 255, 254);
  vertical-align: bottom;
  width: 32px;
  height: 32px;
  color: #666;
  background:rgba(240,240,240,1);
  font-size: 19px;
  border: 1px #eee solid;
}
.cm_num__modifier button:active {
  background: #b1b1b1;
}
.cm_num__modifier input {
  padding: 0;
  width: 50px;
  height: 32px;
  color: #333;
  font-size: 14px;
  text-align: center;
  background: #fff;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-left: none;
  border-right: none;
}
.cm_num__modifier .disabled {
  color: #ddd;
}

.consult_box {
  position: fixed;
  overflow: hidden;
  right: 24px;
  bottom: 24px;
  z-index: 99;
  border-radius: 8px;
  box-shadow:0px 12px 48px 16px rgba(0,0,0,0.03),0px 9px 28px 0px rgba(0,0,0,0.2),0px 6px 16px -8px rgba(0,0,0,0.1);
  iframe {
    display: block;
  }
}

.cm_home_fixed__bar {
    width: 80px;
    height: 250px;
    position: fixed;
    right: 0;
    bottom: 50%;
    transform: translateY(50%);
    z-index: 9;
    .bar_box {
      cursor: pointer;
      width: 80px;
      padding: 10px 0;
      line-height: 30px;
      height: 83px;
      font-size: 14px;
      background: #3E424C;
      color: #fff;
      border-bottom: 1px solid #f5f5f5;
      text-align: center;
      &:last-child {
        border-bottom: none;
      }
    }
}

.cm_radio {
  position: relative;
  top: 2px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.15);
  cursor: pointer;

  &.selected {
    border: 1px solid rgba(255, 69, 0, 1);

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: rgba(255, 69, 0, 1);
    }
  }
}


.cm_progress__list {
  z-index: 2;
  overflow: hidden;
  float: right;
  position: relative;
  overflow: hidden;
  &:after {
      content: '';
      display: block;
      position: absolute;
      left: 30px;
      right: 30px;
      top: 15px;
      height: 1px;
      border-bottom: 1px dashed #ccc;
      z-index: 1;
  }
  .progress_item {
    float: left;
    position: relative;
    margin: 0 30px;
    z-index: 3;
    &:first-child {
        margin-left: 0;
        margin-right: 40px;
    }
    &:last-child {
        margin-right: 0;
        margin-left: 40px;
    }
    &.selected {
        .number {
            background: #FF4500; 
            background:rgba(153,153,153,1) linear-gradient(90deg,rgba(255,141,0,1) 0%,rgba(255,99,0,1) 100%);
            color: #fff;
            border: 1px solid transparent;
        }
        p {
            color:#333;
        }
    }
    .number {
        background: white;
        width: 30px;
        height: 30px;
        line-height: 29px;
        border: 1px solid #DEDEDE;
        color: #CCCCCC;
        font-size: 16px;
        border-radius: 15px;
        margin: 0 auto;
        text-align: center;
        position: relative;
        &:after{
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right:0;
            bottom: 0;
            width: 30px;
            height: 30px;
            z-index: -1;
            transform: scaleX(1.4);
            background: #fff;
            transform-origin: 58% 50%;
        }
    }
    p {
        text-align: center;
        font-size: 14px;
        margin-top: 6px;
        color: #999;
    }
  }
}

.app-popup-style-scale.cm_popup__confirm {
  width: 480px;
  border-radius: 2px;
  .confirm_box {
      padding: 30px 30px 20px 30px;
      .icon-warn {
          width: 35px;
          float: left;
          color: #FF4500;
          font-size: 20px;
      }
      .icons-warn-rem {
          margin-right: 5px;
          float: left;
      }
      .title {
          font-size: 16px;
          color: #000;
          font-weight: 500;
          float: left;
          width: 380px;
      }
      .content {
          margin: 12px 35px 0;
      }
      .operates {
          margin-top: 30px;
          .operate_btn {
              cursor: pointer;
              float: right;
              margin-left: 8px;
              padding: 0 15px;
              height: 32px;
              text-align: center;
              border-radius:2px;
              line-height: 32px;
              font-size: 14px;
              &.cancel {
                  color: #666;
                  background: white;
                  border:1px solid rgba(0,0,0,0.15);
              }
              &.ok {
                  background: #FF6F00;
                  color: #fff;
              }
          }
      }
  }
}

.cm_steps_horizontal {
  .item {
    position: relative;
    display: inline-block;
    vertical-align: top;

    .head {
      position: relative;
      text-align: center;
      font-size: 0;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        z-index: 1;
        width: 50%;
        border-top: 1px dashed #ccc;
      }
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        z-index: 1;
        width: 50%;
        border-top: 1px dashed #ccc;
      }

      .inner {
        position: relative;
        z-index: 2;

        display: inline-block;
        width: 30px;
        height: 30px;
        padding: 5px;
        
        box-sizing: content-box;
        background: #fff;

        i {
          display: block;
          height: 100%;
          border-radius: 50%;
          border: 1px solid #ccc;
          
          font-size: 16px;
          color: #ccc;
          line-height: 28px;
          background-color: #fff;
        }
      }
    }

    .main {
      margin: 10px 20px;
      color: #999;
      font-size: 14px;
    }
  }

  .item.active {
    .head {
      .inner {
        i {
          color: #fff;
          border-color: transparent;
          background: #ff6300;
          background: linear-gradient(90deg, rgba(255, 141, 0, 1) 0%, rgba(255, 99, 0, 1) 100%);
        }
      }
    }

    .main {
      color: #333;
    }
  }

  .item.first {
    .head {
      &:before {
        display: none;
      }
    }
  }

  .item.last {
    .head {
      &:after {
        display: none;
      }
    }
  }
}

.cm_steps_vertical {
  .item {
    position: relative;

    .head {
      float: left;
      zoom: 1;

      text-align: center;
      font-size: 0;

      &:before {
        content: "";
        position: absolute;
        left: 13px;
        top: 0;
        z-index: 1;
        height: 100%;
        border-left: 1px dashed #ccc;
      }

      .inner {
        position: relative;
        z-index: 2;

        display: inline-block;
        width: 26px;
        height: 26px;

        i {
          display: block;
          height: 100%;
          border-radius: 50%;
          border: 1px solid #ccc;
          
          font-size: 16px;
          color: #ccc;
          line-height: 24px;
          background-color: #fff;
        }
      }
    }

    .main {
      padding-left: 15px;
      padding-bottom: 20px;

      color: #999;
      font-size: 14px;
      overflow: hidden;
    }
  }

  .item.active {
    .head {
      .inner {
        i {
          color: #fff;
          border-color: transparent;
          background: #ff6300;
          background: linear-gradient(90deg, rgba(255, 141, 0, 1) 0%, rgba(255, 99, 0, 1) 100%);
        }
      }
    }

    .main {
      color: #333;
    }
  }

  .item.last {
    .head {
      &:before {
        display: none;
      }
    }
  }
}
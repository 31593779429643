.lg__logistics_wrapper {
    padding-top: 20px;
    padding-bottom: 56px;
}

.lg__logistics_main {
    padding: 40px 60px;

    .empty_placeholder {
        text-align: center;
        padding: 30px 0;
    }
}

.lg__logistics_process {
    & > .title {
        margin-top: 30px;
        margin-bottom: 20px;
    }

    & > .main {
        padding-top: 10px;
        padding-bottom: 20px;
        border: 1px solid #e0e0e0;
        border-radius: 2px;
    }
}

.lg__order_info {
    & > .title {
        margin-top: 0px;
        margin-bottom: 20px;
    }

    & > .main {
        padding: 20px 10px 20px 10px;
        border: 1px solid #e0e0e0;
        border-radius: 2px;
    }
}

.lg__order_info {
    .product {
        display: inline-block;
        vertical-align: top;

        .image {
            position: relative;
            width: 50px;
            height: 50px;
            border: 1px solid #e0e0e0;

            img {
                display: block;
                width: 100%;
                height: 100%;
            }

            .count {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;

                font-size: 12px;
                text-align: center;
                color: #fff;
                background: #83766e;
            }
        }
    }
    .express {
        display: inline-block;
        margin-left: 20px;
        vertical-align: top;

        .orderId {
            margin-top: 10px;
            font-size: 14px;
            color: #757575;
        }
    }
    .logistics_status {
        display: inline-block;
        display: none;
        color: #ff6f00;
    }
}

.lg__logistics_process_steps {
    margin: 10px 10px;

    .head {
        &:before {
            display: none;
        }

        .inner {
            i {
                width: 6px !important;
                height: 6px !important;
                margin-left: auto;
                margin-right: auto;
                background: #e0e0e0 !important;
                margin-top: 10px;
            }
        }
    }

    .main {
        padding-left: 0 !important;
    }

    .desc {
        font-size: 16px;
        color: #333;
    }

    .date {
        margin-top: 5px;
        color: #bdbdbd;
        line-height: 22px;
    }

    .active {
        font-weight: bold;

        .desc, .date {
            color: #ff6f00 !important;
        }

        .head {
            .inner {
                i {
                    background: #ff6f00 !important;
                }
            }
        }
    }
}
.as__order_table {
    margin-bottom: 34px;
    border-collapse: collapse;
    width: 100%;

    thead {
        th {
            font-size: 14px;
            font-weight: normal;
            white-space: nowrap;
        }
    }

    .empty_placeholder {
        text-align: center;
        padding: 30px 0;
    }
}

.as__order_row {
    font-size: 12px;
    color: #333;

    &:before {
        content: "";
        display: table-row;
        height: 20px;
    }

    th, td {
        border: 0 solid #ececec;
    }

    th:first-child,
    td:first-child {
        border-left-width: 1px;
    }

    th:last-child,
    td:last-child {
        border-right-width: 1px;
    }

    tr:first-child th,
    tr:first-child td {
        border-top-width: 1px;
    }

    tr:last-child th,
    tr:last-child td {
        border-bottom-width: 1px;
    }

    .sub_hd {
        // border-bottom: 1px solid #ececec;
        td {
            padding: 15px;
            background: #fcfcfc;
        }
    }

    .sub_bd {
        td {
            border: 1px solid #ececec;
        }

        &:hover {
            background: #fff9e7;
        }
    }

    .btn_detail {
        display: inline-block;
        width: 68px;
        line-height: 28px;
        border: 1px solid #999;
        border-radius: 4px;
        color: #999;
        cursor: pointer;
    }
}
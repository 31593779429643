@import './variables.scss';

.mall_common__menu {
    padding-bottom: 4px;
    background: #fff;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
}
.mall_common__menu_container {
    position: relative;
    width: $web_width;
    margin-left: auto;
    margin-right: auto;
    
    &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 100%;
        z-index: 1;
        width: 100%;
        height: 4px;
        background-color: #3e424c;
    }
}
.mall_common__menu_list {
    display: inline-block;

    & > li {
        display: inline-block;
        margin-left: 30px;
        margin-right: 30px;

        font-size: 18px;
        font-weight: bold;
        color: #333;
        cursor: pointer;
    }
}

.mall_common__menu_list_item_important {
    color: #ff6f00 !important;
}
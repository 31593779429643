$pay-result-padding: 9px 48px 9px;
$pay-result-btn-mtop: 18px;
$pay-result-btn-mbottom: 40px;
$pay-result-btn-fontsize: 16px;
$pay-success-padding-left: 50px;
$pay-spinner-margin-top: 40px;

.pay_success_container {
    padding: 0 $pay-success-padding-left;
}
.pay_result_container {
    background-color: #FFF8E9;
    border: 1px solid #ECECEC;
}

.pay_result_icon {
    width: 59px;
    height: 59px;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.pay_result_loading {
    width: 43px;
    height: 43px;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}
.pay_success_text {
    font-size: 24px;
    font-weight: 400;
    margin-top: 15px;
    text-align: center;
    color: #666;
}

.pay_success_desc {
    font-size: 16px;
    margin-top: 10px;
    text-align: center;
    color: #666;
}

.pay_success_look_orders_btn {
    background-color: #FF4500;
    border-radius: 2px;
    color: white;
    font-size: $pay-result-btn-fontsize;
    padding: $pay-result-padding;
    margin-top: $pay-result-btn-mtop;
    margin-bottom: $pay-result-btn-mbottom;
}

.btn_left {
    margin-left: 20px;
}

.pay_success_finish_buy_btn {
    font-size: $pay-result-btn-fontsize;
    color: #666666;
    background-color: white;
    border: 1px solid #ECECEC;
    border-radius: 2px;
    padding: $pay-result-padding;
    margin-top: $pay-result-btn-mtop;
    margin-bottom: $pay-result-btn-mbottom;
}

.opr_orders_wrapper {
    margin-top: 25px;
    padding-top: 10px;

    p {
        font-size: 16px;
        color: #333;
    }

    table {
        margin-top: 9px;
        font-size: 14px;
        border-collapse: collapse;
        width: 100%;
    }

    tr {
        border: 1px solid #ECECEC;
        line-height: 30px;
    }

    tr th, tr td {
        text-align: left;
        padding-left: 20px;
    }

    // tr th:last-child, tr td:last-child {
    //     padding-right: 10px;
    //     padding-left: 0;
    // }

    tr:first-child {
        background-color: #F5F5F5;
        font-size: 14px;
        color: #666666;
    }
}

.pay_result_spinner {
    margin: 0 auto;
    width: 43px;
    height: 43px;
    position: relative;

    .container1 > div, .container2 > div, .container3 > div {
        width: 8px;
        height: 8px;
        background-color: #FF4500;
    
        border-radius: 100%;
        position: absolute;
        -webkit-animation: bouncedelay 1.2s infinite ease-in-out;
        animation: bouncedelay 1.2s infinite ease-in-out;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
    
    .spinner-container {
        position: absolute;
        width: 100%;
        height: 100%;
    }
    
    .container2 {
        -webkit-transform: rotateZ(45deg);
        transform: rotateZ(45deg);
    }
    
    .container3 {
        -webkit-transform: rotateZ(90deg);
        transform: rotateZ(90deg);
    }
    
    .circle1 { top: 0; left: 0; }
    .circle2 { top: 0; right: 0; }
    .circle3 { right: 0; bottom: 0; }
    .circle4 { left: 0; bottom: 0; }
    
    .container2 .circle1 {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s;
    }
    
    .container3 .circle1 {
        -webkit-animation-delay: -1.0s;
        animation-delay: -1.0s;
    }
    
    .container1 .circle2 {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s;
    }
    
    .container2 .circle2 {
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s;
    }
    
    .container3 .circle2 {
        -webkit-animation-delay: -0.7s;
        animation-delay: -0.7s;
    }
    
    .container1 .circle3 {
        -webkit-animation-delay: -0.6s;
        animation-delay: -0.6s;
    }
    
    .container2 .circle3 {
        -webkit-animation-delay: -0.5s;
        animation-delay: -0.5s;
    }
    
    .container3 .circle3 {
        -webkit-animation-delay: -0.4s;
        animation-delay: -0.4s;
    }
    
    .container1 .circle4 {
        -webkit-animation-delay: -0.3s;
        animation-delay: -0.3s;
    }
    
    .container2 .circle4 {
        -webkit-animation-delay: -0.2s;
        animation-delay: -0.2s;
    }
    
    .container3 .circle4 {
        -webkit-animation-delay: -0.1s;
        animation-delay: -0.1s;
    }
    
    @-webkit-keyframes bouncedelay {
        0%, 80%, 100% { -webkit-transform: scale(0.0) }
        40% { -webkit-transform: scale(1.0) }
    }
    
    @keyframes bouncedelay {
        0%, 80%, 100% {
            transform: scale(0.0);
            -webkit-transform: scale(0.0);
        } 40% {
            transform: scale(1.0);
            -webkit-transform: scale(1.0);
        }
    }
}

.pay_result_spinner_margin {
    margin: $pay-spinner-margin-top auto 0;
}
.cm_anchor {
    width: 122px;
    text-align: center;

    ul {
        padding: 10px 0;
        font-size: 16px;

        li {
            height: 37px;
            line-height: 37px;
            cursor: pointer;

            .icon-location1 {
                left: 12px;
                position: absolute;
            }
        }
    }

    .backTop {
        height: 73px;
        font-size: 14px;
        background: rgba(255, 255, 255, 0.2);
        cursor: pointer;

        .icon-up {
            line-height: 38px;
            font-size: 30px;
        }
    }
}

.md_pagination {
    .show_total,.container {
        float: left;
    }

    .show_total {
        font-size: 12px;
        padding: 4px 10px;
        margin: 2px;
    }
}

.md_pagination li {
    padding: 4px 10px;
    border-radius: 4px;
    background: #f1f1f1;
    border: 2px solid #efefef;
    margin: 2px;
    min-width: 32px;
    list-style: none;
    font-size: 12px;
    text-align: center;
    float: left;

    &.active {
        color: white;
        background: rgba(255, 111, 0, 1);
        border: 2px solid transparent;

        &:hover {
            background: pink;
        }
    }

    &:hover {
        cursor: pointer;
        background: #ececec;
    }
}

.md_pagination .previos,
.md_pagination .next {
    pointer-events: visible;
    opacity: 1;

    &.disable {
        pointer-events: none;
        opacity: 0.4;
    }
}

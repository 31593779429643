.address-save-btn { width: 100px; height: 32px; line-height: 32px; color: #fff; font-size: 14px; background-color: #FF6F00; border-radius: 2px; cursor: pointer;}
.address-set-default label { margin-left: 5px; padding-top: 20px; vertical-align: 2px; color: #666; }

.address-form-tags .tag { height: 24px; width: 58px; border: 1px solid #ccc; border-radius: 12px; margin-right: 10px; font-size: 14px; color: #666; cursor: pointer;}
.address-form-tags .tag.checked {background-color: #ff6f00; border: 1px solid #ff6f00; color: #fff;}
.address-suggest-addresses { position: absolute; top: 32px; left: 0px; z-index: 10; background-color: #fff; border: 1px solid #ddd; padding: 7px 10px; font-size: 12px; max-height: 200px; overflow: auto; }
.address-suggest-addresses li { padding: 3px 0; cursor: default; }
.address-suggest-addresses .red { color: #c00; }

.address-list-item {
    position: relative; padding: 15px; border: 1px solid #eee; border-radius: 4px; margin-bottom: 20px;
    &:hover { background-color: #fff9e7; border: 1px solid #ff6f00; }
    li {
        margin-top: 5px; font-size: 14px;
        label { display: inline-block; width: 70px; height: 22px; line-height: 22px; color: #999; text-align: right; }
        p { display: inline-block; height: 22px; line-height: 22px; color: #666; }
        .name { font-weight: bold; }
    }
    .tags {
        position: absolute; top: 15px; right: 15px;
        .tag { display: inline-block; margin-left: 5px; padding: 0 6px; height: 24px; line-height: 24px; background-color: rgba(255, 111, 0, 0.1); font-size: 12px; color: #ff6f00; }
    }
    .actions {
        position: absolute; right: 15px; bottom: 15px;
        button {
            height: 24px; line-height: 24px; color: #333; font-size: 12px;
            &:hover { cursor: pointer; }
        }
        .seperator { margin: 0 15px; font-size: 12px; color: #999; }
    }
}

.address-editor-modal.app-popup-style-scale { border-radius: 2px; padding: 0px; width: 600px; }
.address-editor-modal .app-popup-container-scale { border-radius: 2px; padding: 0px; }
.address-editor-modal-hd { border-bottom: 1px dashed #E0E0E0; height: 56px; line-height: 56px; font-size: 16px; padding-left: 20px; }
.address-editor-modal-con { padding: 20px; }
.address-editor-modal-ft { border-top: 1px solid #E0E0E0; padding: 10px 20px; }
.address-editor-modal-ft button { width: 65px; height: 32px; border-radius: 2px; font-size: 14px; cursor: pointer; }
.address-editor-modal-ft .btn_save { background-color: #FF6F00; color: #fff; margin-left: 20px; }
.address-editor-modal-ft .btn_cancel { border: 1px solid rgba(0,0,0,0.15); color: #666; margin-left: 20px; }
$color_orange: #ff4500;

.co {
  background-color: #fff;
  padding-bottom: 40px;

  .body {
    width: 1180px;
    margin: 0 auto;
  }

  .cs_p {
    cursor: pointer;
  }

  .promotion {
    height: 22px;

    .prom {
      margin-right: 12px;
    }

    .tag {
      margin-right: 10px;
    }

    .time {
      margin-right: 30px;
    }

    & + .promotion {
      margin-top: 10px;
    }

    &.selected {
      .prom {
        color: $color_orange;
      }
    }
  }
}

.co_title {
  height: 22px;
  line-height: 22px;
}

.co_receivers {
  margin-bottom: 25px;

  .btn_add {
    width: 182px;
    height: 90px;
    border: 1px solid rgba(204, 204, 204, 1);
    position: relative;

    .content {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -22px;
      margin-left: -35px;

      .icon_add {
        width: 21px;
        height: 21px;
        line-height: 16px;
        display: inline-block;
        border: solid 2px $color_orange;
        color: $color_orange;
        border-radius: 50%;
        margin-bottom: 4px;
        font-weight: bold;
      }
    }
  }

  .receiver {
    width: 280px;
    height: 130px;
    padding: 12px 15px 10px;
    border: solid 1px #ccc;

    .title_wrap {
      padding-bottom: 8px;
      border-bottom: dashed 1px #ccc;

      .title {
        height: 22px;
        line-height: 22px;
      }
    }

    .address {
      line-height: 17px;
      max-height: 34px;
    }

    .bottom {
      left: 0;
      bottom: 10px;

      .btn_modify {
        color: $color_orange;
      }
    }

    .img_selected {
      position: absolute;
      bottom: -1px;
      right: -1px;
      width: 40px;
      height: 40px;
    }

    &.selected {
      border: solid 4px $color_orange;
      background-color: rgba(255, 249, 231, 1);
    }

    & + .receiver {
      margin-left: 20px;
    }

    &:nth-of-type(4n + 1) {
      margin-left: 0px;
    }
  }

  .btn_show_all {
    display: inline-block;
    color: $color_orange;

    .icon-down {
      position: relative;
      top: 1px;
    }
  }
}

.co_ci {
  margin-bottom: 27px;

  .items_header {
    height: 43px;
    line-height: 43px;
    border-top: solid 1px #ccc;
  }

  .cols {
    .info {
      width: 448px;
      padding-left: 20px;
      float: left;
    }

    .spec {
      width: 245px;
      float: left;
    }

    .single_price {
      width: 137px;
      float: left;
    }

    .count {
      width: 172px;
      float: left;
    }

    .total {
      width: 157px;
      float: left;
    }
  }

  .seller_block {
    > .title {
      background-color: #fcfcfc;
      height: 50px;
      padding: 15px 20px 16px;
      border: solid 1px #ececec;
    }

    .item {
      padding: 20px 0;
      border: solid 1px #ececec;
      border-top: none;

      .info {
        margin-top: 7px;

        .img_wrap {
          position: relative;
          width: 80px;
          height: 80px;

          .img {
            width: 80px;
            height: 80px;
          }

          .mask {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            .mask_content {
              width: 40px;
              height: 40px;
              line-height: 40px;
              position: absolute;
              left: 50%;
              right: 50%;
              margin-top: 20px;
              margin-left: -20px;
              background-color: rgba(0, 0, 0, 0.5);
              text-align: center;
              color: #fff;
              border-radius: 50%;
              font-size: 11px;

              &.oos {
                line-height: initial;
                padding-top: 4px;
              }
            }
          }
        }

        > .title {
          width: 250px;
          line-height: 20px;
          max-height: 60px;
        }
      }

      .spec {
        margin-top: 7px;
        .line {
          width: 190px;
        }

        .line + .line {
          margin-top: 4px;
        }
      }

      .single_price {
        margin-top: 7px;
      }

      .count {
        .piece {
          margin-top: 7px;
        }
      }

      .total {
        margin-top: 7px;
      }
    }

    & + .seller_block {
      margin-top: 15px;
    }
  }

  .check {
    background-color: #fff9e7;
    padding: 14px 20px 18px;
    border: 1px solid rgba(236, 236, 236, 1);
    border-top: none;

    .coupons {
      margin-bottom: 13px;
    }

    .bottom {
      .seller_remark {
        .remark_wrap {
          width: 400px;

          .remark {
            height: 70px;
            border-radius: 2px 0px 0px 2px;
            border: 1px solid rgba(0, 0, 0, 0.15);
            padding: 4px 10px;

            &::-webkit-input-placeholder {
              color: rgba(0, 0, 0, 0.25);
            }
            &:-moz-placeholder {
              /* Firefox 18- */
              color: rgba(0, 0, 0, 0.25);
            }
            &::-moz-placeholder {
              /* Firefox 19+ */
              color: rgba(0, 0, 0, 0.25);
            }
            &:-ms-input-placeholder {
              color: rgba(0, 0, 0, 0.25);
            }
          }

          .count {
            position: absolute;
            right: 10px;
            bottom: 5px;
            color: rgba(0, 0, 0, 0.25);
          }
        }
      }

      .sum {
        margin-top: 30px;

        .line {
          .right {
            display: inline-block;
            width: 111px;
          }

          & + .line {
            margin-top: 4px;
          }
        }

        .total {
          .count {
            color: $color_orange;
          }

          .symbol {
            color: $color_orange;
          }

          .money {
            font-size: 18px;
            color: $color_orange;
          }
        }
      }
    }
  }
}

.co_payment {
  margin-bottom: 25px;

  .method {
    width: 100px;
    height: 32px;
    line-height: 32px;
    border: 1px solid rgba(204, 204, 204, 1);

    .img_selected {
      position: absolute;
      right: 0px;
      bottom: 0px;
      width: 20px;
      height: 20px;
    }

    &.selected {
      border: 1px solid rgba(255, 69, 0, 1);
    }

    & + .method {
      margin-left: 10px;
    }
  }
}

.co_cc {
  margin-bottom: 25px;
}

.co_rp {
  margin-bottom: 25px;
}

.co_bottom {
  border-top: 1px solid #ccc;
  padding-top: 25px;
  margin-bottom: 25px;

  .line {
    .left {
      color: #999;
    }

    .right {
      display: inline-block;
      width: 111px;
    }
  }

  .total {
    background: rgba(238, 238, 238, 1);
    padding: 20px;

    .count {
      color: $color_orange;
    }

    .price {
      margin-bottom: 13px;

      .symbol {
        font-size: 22px;
        color: $color_orange;
      }

      .money {
        font-size: 32px;
        color: $color_orange;
      }
    }
  }

  .btn_submit {
    width: 180px;
    height: 50px;
    line-height: 50px;
    font-size: 22px;
    background-color: rgba(255, 69, 0, 1);

    &.disabled {
      background-color: #ccc;
    }
  }
}

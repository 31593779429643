@import "~snowball/sass/variables.scss";
@import "./reset.scss";
@import "~snowball/sass/app.scss";
@import "./common.scss";

.ff_def, body, html, input, button { font-family: '微软雅黑', 'PingFang SC', 'Helvetica Neue', 'Helvetica', 'STHeitiSC-Light', 'Arial', sans-serif; }

body { background-color: #fff; }
html, body { height: 100%; width: auto; }
button[disabled] { cursor: default; }

.app-view { transform: none !important}
.app-viewport, .app-view { position: relative; background-color: #fff; width: auto; overflow: visible; box-shadow: none; }

.md_fixed_wrapper { position: fixed; right: 0; bottom: 200px; }

.app-popup-style-scale { transform: translate(-50%,-50%); }
.app-popup-container-up { transform: translate(0%,100%); }
.app-popup-container.show .app-popup-container-up {transform: translate(0%,0%); }

.app-wrapper, .app-wrapper-gray, .app-wrapper-fff { width: 100%; min-width: 1180px; background-color: #fff; }
.app-wrapper-gray { background-color: rgba(245,245,245,1); background-size: 100% auto; background-repeat: no-repeat;}
.app-main { width: 1180px; margin: 0px auto; position: relative; }
.app-breadcrumbs { width: 1180px; margin: 0px auto; padding: 0 15px; height: 40px; line-height: 40px; background-color: #F8F8F8; font-size: 14px; }
.app-breadcrumbs-item { float: left; color: #666; }
.app-breadcrumbs-arrow { float: left; padding: 0 10px; }
.app-breadcrumbs-arrow::before { content: ''; border-right: 1px solid #999; border-top: 1px solid #999; width: 6px; height: 6px; display: inline-block; transform: rotate(45deg); vertical-align: 2px; }
.app-breadcrumbs .current { width: 200px; float: left; }

.app-popup-style-scale { width: 400px; }
.app-toast { width: 300px; left: 50%; margin-left: -150px; }

.app-signin { width: 3.2rem; }
.app-signin form { width: 80%; margin: 0 auto; }
.app-signin .mobi:after,.app-signin .pwd:after { border-color: #bdbdbd; border-radius: 10px; }
.app-signin .btn { width: 100%; }

.app-form-item { margin-bottom: 15px; }
.app-form-label { display: inline-block; width: 90px; font-size: 14px; color: #666; margin-left: 3px; }
.app-form-error { display: inline-block; color: #ff4500; font-size: 14px; margin-left: 10px; }
.app-form-error i { font-size: 14px; }
.app-form-required { margin-right: 4px; color: #ff4500; }
.app-form-input-wrap { display: inline-block; vertical-align: middle; position: relative; }
.app-form-input { width: 250px; height: 32px; border: 1px solid #ececec; border-radius: 2px; padding: 0 8px; color: #333; outline: none; font-size: 14px; }
.app-form-input.wide { width: 300px; }
.app-form-input.tight { width: 80px; }

.app-form-tag { margin-right: 10px; width: 58px; height: 24px; line-height: 24px; border-radius: 12px; border: 1px solid #ccc; font-size: 14px; background-color: #fff; color: #666; }
.app-form-tag.checked { background-color: #ff6f00; color: #fff; border: none; }

.app-global-address-select { position: relative; display: inline-block; border: 1px solid #E0E0E0; height: 30px; border-radius: 2px; }
.app-global-address-select-con { height: 28px; font-size: 12px; color: #666; padding: 0 25px 0 10px; }
.app-global-address-select-con::after { content: ""; display: block; width: 8px; height: 8px; border-top: 1px solid #999; border-left: 1px solid #999; position: absolute; right: 8px; top: 8px; transform: rotate(-135deg); }
.app-global-address-select-modal { position: absolute; background-color: #fff; box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.15); border: 1px solid rgba(224,224,224,1); z-index: 10000; }
.app-global-address-select-modal h5 { font-size: 12px; margin-top: 15px; padding-left: 10px; padding-bottom: 8px; }
.app-global-address-select-modal .addressList { border-bottom: 1px solid #E0E0E0; padding: 0 10px 10px 10px; }
.app-global-address-select-modal .addressList li { line-height: 25px; font-size: 14px; color: #999; cursor: pointer; }
.app-global-address-select-modal .addressList li.curr { color: #ff6f00; }
.app-global-address-select-modal .no-address { padding: 15px; font-size: 14px; color: #666; }

/* 公共地址选择 */
.app-district-dropdown { position: relative; }
.app-district-dropdown-main { position: relative; font-size: 14px; height: 32px; border: 1px solid #ececec; border-radius: 2px; padding: 0 20px 0 8px; color: #333; outline: none; line-height: 32px; text-align: left; min-width: 250px; }
.app-district-dropdown-main .placeholder { color: #999; }
.app-district-dropdown-main::after { content: ""; display: block; width: 8px; height: 8px; border-top: 1px solid #999; border-left: 1px solid #999; position: absolute; right: 10px; top: 8px; transform: rotate(-135deg); }
.app-district-dropdown-main.open::after { transform: rotate(45deg); top: 12px; }

.app-district-dropdown-selector { position: absolute; z-index: 100; width: 420px; background-color: #fff; border: 1px solid #ececec; }

.app-district-selector { width: 400px; background-color: #fff; border-radius: 2px; padding: 0 8px; color: #333; }
.app-district-selector .tab li { margin-right: 4px; font-size: 12px; height: 24px; line-height: 24px; border: 1px solid #ddd; border-bottom: 0; border-top-left-radius: 2px; border-top-right-radius: 2px; background: #fff; position: relative; z-index: 1; padding-left: 10px; padding-right: 24px; }
.app-district-selector .tab li::after { content: ""; display: block; width: 6px; height: 6px; border-top: 1px solid #999; border-left: 1px solid #999; position: absolute; right: 10px; top: 6px; transform: rotate(-135deg); }
.app-district-selector .tab li.curr { height: 25px; border-color: #FF6F00; color: #FF6F00; margin-top: 1px; }
.app-district-selector .tab li.curr::after { border-color: #FF6F00; }
.app-district-selector .list { border-top: 1px solid #ff6f00; margin-top: -1px; padding-top: 14px; padding-bottom: 14px; }
.app-district-selector .list li { width: 25%; line-height: 24px; font-size: 12px; cursor: pointer; }
.app-district-selector .list li.curr { color: #ff6f00; cursor: default; }
.app-district-dropdown-close { position: absolute; right: 0; top: 0; padding: 4px 10px; }

.app-select { min-width: 160px; border: 1px solid #ececec; line-height: 32px; background: none; font-size: 14px; }
.app-page-error {
    padding: 150px 0;
    img {
        display: block;
        width: 180px;
        height: 175px;
        margin: 50px auto 0;
    }
    p {
        text-align: center;
        font-size: 48px;
        margin: 10px;
    }
}

/* 模块通用 */
.bk_mt { margin-top: 10px; }
.bk_mb { margin-bottom: 10px; }
.bk_mlr { margin-left: 10px; margin-right: 10px; }
.bk_pt { padding-top: 10px; }
.bk_pb { padding-bottom: 10px; }
.bk_plr { padding-left: 10px; padding-right: 10px; }

.mb_100 { margin-bottom: 100px; }

@import "./usercenter.scss";
@import "../app/order-create/styles/order-create.scss";
@import "../app/cart/styles/cart.scss";
@import "../app/checkout//styles/checkout.scss";
.cm_category {
    width: 230px;
    background-color: #3e424c;
    color: white;
    font-size: 16px;
    z-index: 10;
    cursor: pointer;

    .level_1 {
        list-style: none;

        li {
            line-height: 50px;
            height: 50px;
            align-items: center;

            .round {
                width: 20px;
                height: 20px;
                border-radius: 20px;
                display: inline-block;
                margin-left: 20px;
                margin-right: 8px;
                background-size: 100% 100%;
            }

            .icon-next {
                margin-right: 31px;
            }

            &:hover {
                background-color: white;
                color: #ff6f00;
            }
        }
    }

    .level_2 {
        position: absolute;
        width: 710px;
        height: 100%;
        left: 100%;
        top: 0;
        padding-top: 20px;
        padding-left: 30px;
        background: white;

        > li {
            min-height: 20px;
            margin-bottom: 20px;

            .second_name {
                min-width: 56px;
                text-align: center;
                font-size: 14px;
                font-weight: 500;
                color: rgba(51, 51, 51, 1);
                line-height: 20px;
                align-self: flex-start;
                margin-right: 18px;
                cursor: initial;
            }
        }
    }

    .level_3 {
        > li {
            font-size: 14px;
            font-weight: 400;
            color: rgba(102, 102, 102, 1);
            float: left;
            margin-right: 15px;
        }
    }
}

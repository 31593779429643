@charset "UTF-8";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  font-style: normal;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  list-style: none;
  box-sizing: border-box; }

body, html {
  color: #333;
  width: 100%;
  height: 100%; }

html {
  font-size: 100px; }

body {
  font-size: 16px; }

a {
  text-decoration: none; }

input, button, textarea {
  -webkit-appearance: none;
  outline: none;
  box-sizing: border-box;
  line-height: normal;
  vertical-align: middle; }

input[type=button], button {
  padding: 0;
  border: 0;
  border-radius: 0; }

input::-webkit-input-placeholder {
  color: #999; }

button[disabled] {
  opacity: .5; }

textarea {
  resize: none; }

img:not([src]), img[src=""] {
  background-color: rgba(200, 200, 200, 0.5); }

button {
  background-color: transparent; }

.ff_def, body, html {
  font-family: "PingFang SC", "Helvetica Neue", "Helvetica", "STHeitiSC-Light", "Arial", sans-serif; }

.flex, .flex_r, .app-header, .app-header-con, .app-header-left, .app-header-right {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center; }
  .flex > a, .flex_r > a, .app-header > a, .app-header-con > a, .app-header-left > a, .app-header-right > a {
    display: block; }
  .flex > a:active, .flex_r > a:active, .app-header > a:active, .app-header-con > a:active, .app-header-left > a:active, .app-header-right > a:active {
    background-color: rgba(0, 0, 0, 0.1); }

.flexwrap {
  display: -webkit-box;
  -webkit-box-lines: multiple;
  display: flex;
  flex-wrap: wrap; }

.flex_r {
  -webkit-box-direction: reverse;
  -webkit-box-pack: end;
  flex-direction: row-reverse; }

.fd_r {
  -webkit-box-orient: horizontal;
  flex-direction: row; }

.fd_rr {
  -webkit-box-direction: reverse;
  flex-direction: row-reverse; }

.fd_c {
  -webkit-box-orient: vertical;
  flex-direction: column; }

.fd_cr {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  flex-direction: column-reverse; }

.jc_c {
  -webkit-box-pack: center;
  justify-content: center; }

.jc_e {
  -webkit-box-pack: end;
  justify-content: flex-end; }

.jc_sb {
  -webkit-box-pack: justify;
  justify-content: space-between; }

.jc_sa {
  -webkit-box-pack: justify;
  justify-content: space-around; }

.ai_c {
  -webkit-box-align: center;
  align-items: center; }

.ai_s {
  align-items: stretch; }

.ai_b {
  -webkit-box-align: baseline;
  align-items: baseline; }

.ai_fs, .flex_r {
  -webkit-box-align: start;
  align-items: flex-start; }

.ai_fe {
  -webkit-box-align: end;
  align-items: flex-end; }

.as_c {
  align-self: center; }

.as_s {
  align-self: stretch; }

.as_fs {
  align-self: flex-start; }

.as_fe {
  align-self: flex-end; }

.flexavg, .flex_avg, .flex_0 {
  display: block;
  -webkit-box-flex: 1;
  flex: 1;
  width: 0px; }

.flexitem, .flex_1 {
  display: block;
  -webkit-box-flex: 1;
  flex: 1; }

.fx_n {
  flex: none; }

.fx_0 {
  flex: 0; }

.fx_1 {
  flex: 1; }

.fx_2 {
  flex: 2; }

.fx_3 {
  flex: 3; }

.fx_4 {
  flex: 4; }

input.flexitem, input.flex_1, input.flex_0 {
  border: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0); }

.of_h {
  overflow: hidden; }

.of_v {
  overflow: visible; }

.of_s {
  overflow: scroll;
  -webkit-overflow-scrolling: touch; }

.ofx_h {
  overflow-x: hidden; }

.ofx_a {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

.ofx_s {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch; }

.ofy_h {
  overflow-y: hidden; }

.ofy_a {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

.ofy_s {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; }

.dp_f {
  display: flex; }

.dp_b {
  display: block; }

.dp_i {
  display: inline; }

.dp_n {
  display: none; }

.dp_ib {
  display: inline-block; }

.vb_hd {
  visibility: hidden; }

.clearfix {
  zoom: 1; }

.clearfix:after {
  height: 0px;
  display: block;
  font-size: 0px;
  content: '';
  clear: both; }

.fl_r {
  float: right; }

.fl_l {
  float: left; }

.cl_l {
  clear: left; }

.cl_r {
  clear: right; }

.cl_b {
  clear: both; }

.ps_r {
  position: relative; }

.ps_a {
  position: absolute; }

.ps_f {
  position: fixed; }

.ps_s {
  position: static; }

.ps_c {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.va_t {
  vertical-align: top; }

.va_m {
  vertical-align: middle; }

.va_b {
  vertical-align: bottom; }

.va_tb {
  vertical-align: text-bottom; }

.ta_c {
  text-align: center; }

.ta_r {
  text-align: right; }

.ta_l {
  text-align: left; }

.td_u {
  text-decoration: underline; }

.to_e {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.to_e2, .to_e3 {
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; }

.to_e2:before, .to_e3:before {
  content: ''; }

.to_e3 {
  -webkit-line-clamp: 3; }

.dock, .app-header-con {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0; }

.dock_t {
  position: absolute;
  top: 0; }

.dock_b {
  position: absolute;
  bottom: 0; }

.dock_l {
  position: absolute;
  left: 0; }

.dock_r {
  position: absolute;
  right: 0; }

.dock_tb {
  position: absolute;
  top: 0;
  bottom: 0; }

.dock_tl {
  position: absolute;
  top: 0;
  left: 0; }

.dock_tlr {
  position: absolute;
  top: 0;
  left: 0;
  right: 0; }

.dock_tr {
  position: absolute;
  top: 0;
  right: 0; }

.dock_tbl, .app-header-left {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0; }

.dock_tbr, .app-header-right {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0; }

.dock_blr {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0; }

.dock_bl {
  position: absolute;
  bottom: 0;
  left: 0; }

.dock_br {
  position: absolute;
  bottom: 0;
  right: 0; }

.dock_lr {
  position: absolute;
  left: 0;
  right: 0; }

.pd_s {
  padding: 5px; }

.pd_m {
  padding: 10px; }

.pd_l {
  padding: 15px; }

.pd_xl {
  padding: 20px; }

.pd_xxl {
  padding: 30px; }

.pt_0 {
  padding-top: 0px; }

.pt_1 {
  padding-top: 1px; }

.pt_xs {
  padding-top: 3px; }

.pt_s {
  padding-top: 5px; }

.pt_6 {
  padding-top: 6px; }

.pt_m {
  padding-top: 10px; }

.pt_l {
  padding-top: 15px; }

.pt_xl {
  padding-top: 20px; }

.pt_xxl {
  padding-top: 30px; }

.pt_sr {
  padding-top: .05rem; }

.pt_6r {
  padding-top: .06rem; }

.pt_8r {
  padding-top: .08rem; }

.pt_mr {
  padding-top: .1rem; }

.pt_lr {
  padding-top: .15rem; }

.pt_xlr {
  padding-top: .2rem; }

.pt_xxlr {
  padding-top: .3rem; }

.pr_6r {
  padding-right: .06rem; }

.pl_6r {
  padding-left: .06rem; }

.pb_6r {
  padding-bottom: .06rem; }

.pl_6r {
  padding-left: .06rem; }

.pr_6r {
  padding-right: .06rem; }

.pl_0 {
  padding-left: 0px; }

.pl_s {
  padding-left: 5px; }

.pl_m {
  padding-left: 10px; }

.pl_l {
  padding-left: 15px; }

.pl_xl {
  padding-left: 20px; }

.pl_xxl {
  padding-left: 30px; }

.pl_sr {
  padding-left: .05rem; }

.pl_mr {
  padding-left: .1rem; }

.pl_lr {
  padding-left: .15rem; }

.pl_xlr {
  padding-left: .2rem; }

.pl_xxlr {
  padding-left: .3rem; }

.pr_0 {
  padding-right: 0px; }

.pr_s {
  padding-right: 5px; }

.pr_m {
  padding-right: 10px; }

.pr_l {
  padding-right: 15px; }

.pr_xl {
  padding-right: 20px; }

.pr_sr {
  padding-right: .05rem; }

.pr_mr {
  padding-right: .1rem; }

.pr_lr {
  padding-right: .15rem; }

.pr_xlr {
  padding-right: .2rem; }

.pr_xxlr {
  padding-right: .3rem; }

.pb_s {
  padding-bottom: 5px; }

.pb_m {
  padding-bottom: 10px; }

.pb_l {
  padding-bottom: 15px; }

.pb_xl {
  padding-bottom: 20px; }

.pb_xxl {
  padding-bottom: 30px; }

.pb_sr {
  padding-bottom: .05rem; }

.pb_8r {
  padding-bottom: .08rem; }

.pb_mr {
  padding-bottom: .1rem; }

.pb_lr {
  padding-bottom: .15rem; }

.pb_xlr {
  padding-bottom: .2rem; }

.pb_xxlr {
  padding-bottom: .3rem; }

.mg_0 {
  margin: 0; }

.mg_10r {
  margin: .1rem; }

.mg_s {
  margin-left: 10px;
  margin-top: 10px;
  margin-right: 10px; }

.mg_m {
  margin-left: 10px;
  margin-top: 10px;
  margin-right: 10px; }

.mg_l {
  margin-left: 15px;
  margin-top: 15px;
  margin-right: 15px; }

.mg_xl {
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 20px; }

.mg_a {
  margin: auto; }

.mh_s {
  margin-left: 5px;
  margin-right: 5px; }

.mh_m {
  margin-left: 10px;
  margin-right: 10px; }

.mh_l {
  margin-left: 15px;
  margin-right: 15px; }

.mh_xl {
  margin-left: 20px;
  margin-right: 20px; }

.ml_1 {
  margin-left: 1px; }

.ml_2 {
  margin-left: 2px; }

.ml_3 {
  margin-left: 3px; }

.ml_s {
  margin-left: 5px; }

.ml_m {
  margin-left: 10px; }

.ml_l {
  margin-left: 15px; }

.ml_xl {
  margin-left: 20px; }

.mr_1 {
  margin-right: 1px; }

.mr_2 {
  margin-right: 2px; }

.mr_3 {
  margin-right: 3px; }

.mr_s {
  margin-right: 5px; }

.mr_m {
  margin-right: 10px; }

.mr_l {
  margin-right: 15px; }

.mr_xl {
  margin-right: 20px; }

.mt_xs {
  margin-top: 3px; }

.mt_s {
  margin-top: 5px; }

.mt_8 {
  margin-top: 8px; }

.mt_m {
  margin-top: 10px; }

.mt_l {
  margin-top: 15px; }

.mt_xl {
  margin-top: 20px; }

.mt_xxl {
  margin-top: 30px; }

.mb_s {
  margin-bottom: 5px; }

.mb_8 {
  margin-bottom: 8px; }

.mb_m {
  margin-bottom: 10px; }

.mb_l {
  margin-bottom: 15px; }

.mb_xl {
  margin-bottom: 20px; }

.mb_xxl {
  margin-bottom: 30px; }

.mt_1 {
  margin-top: 1px; }

.mt_2 {
  margin-top: 2px; }

.mt_2r {
  margin-top: .2rem; }

.mt_6r {
  margin-top: .06rem; }

.mt_8r {
  margin-top: .08rem; }

.mt_xsr {
  margin-top: .03rem; }

.mt_sr {
  margin-top: .05rem; }

.mt_mr {
  margin-top: .1rem; }

.mt_lr {
  margin-top: .15rem; }

.mt_xlr {
  margin-top: .2rem; }

.mt_xxlr {
  margin-top: .3rem; }

.mb_6r {
  margin-bottom: .06rem; }

.mb_8r {
  margin-bottom: .08rem; }

.ml_sr {
  margin-left: .05rem; }

.ml_mr {
  margin-left: .1rem; }

.ml_lr {
  margin-left: .15rem; }

.mr_sr {
  margin-right: .05rem; }

.mr_mr {
  margin-right: .1rem; }

.mr_lr {
  margin-right: .15rem; }

.mb_sr {
  margin-bottom: .05rem; }

.mb_mr {
  margin-bottom: .1rem; }

.mb_lr {
  margin-bottom: .15rem; }

.mb_xlr {
  margin-bottom: .2rem; }

.mb_xxlr {
  margin-bottom: .3rem; }

.fs_0 {
  font-size: 0px; }

.fs_xxs {
  font-size: 8px; }

.fs_xxsr {
  font-size: .08rem; }

.fs_xs {
  font-size: 10px; }

.fs_xsr {
  font-size: .1rem; }

.fs_s {
  font-size: 12px; }

.fs_sr {
  font-size: .12rem; }

.fs_m {
  font-size: 14px; }

.fs_mr {
  font-size: .14rem; }

.fs_l {
  font-size: 16px; }

.fs_lr {
  font-size: .16rem; }

.fs_xl {
  font-size: 18px; }

.fs_xlr {
  font-size: .18rem; }

.fs_20 {
  font-size: 20px; }

.fs_22 {
  font-size: 22px; }

.fs_xxl {
  font-size: 24px; }

.fs_xxlr {
  font-size: .24rem; }

.fs_15r {
  font-size: .15rem; }

.fw_xs {
  font-weight: 100; }

.fw_s, .fw_l {
  font-weight: 200; }

.fw_m, .fw_n {
  font-weight: normal; }

.fw_b {
  font-weight: bold; }

.cl_app {
  color: #ff6f00; }

.cl_dark {
  color: #333; }

.cl_light {
  color: #ccc; }

.cl_fff {
  color: #fff; }

.cl_aaa {
  color: #aaa; }

.cl_bbb {
  color: #bbb; }

.cl_ccc {
  color: #ccc; }

.cl_999 {
  color: #999; }

.cl_666 {
  color: #666; }

.cl_333 {
  color: #333; }

.cl_000 {
  color: #000; }

.cl_red {
  color: #d03939; }

.bg_non, .bg_0000, .transparent, .bg_trans {
  background-color: rgba(0, 0, 0, 0); }

.bg_fff {
  background-color: #fff; }

.bg_f7 {
  background-color: #f7f7f7; }

.bg_f2 {
  background-color: #f2f2f291; }

.bg_eee {
  background-color: #eee; }

.bg_ddd {
  background-color: #ddd; }

.bg_ccc {
  background-color: #ccc; }

.bg_000 {
  background-color: #000; }

.bg_333 {
  background-color: #333; }

.bg_app {
  background-color: #ff6f00; }

.bg_deep {
  background-color: #ff4500; }

.nobg {
  background: none !important; }

.lh_xxs {
  line-height: 20px; }

.lh_xs {
  line-height: 24px; }

.lh_s {
  line-height: 34px; }

.lh_m {
  line-height: 40px; }

.lh_l {
  line-height: 50px; }

.lh_xl {
  line-height: 60px; }

.lh_bar {
  line-height: 44px; }

.lh_0 {
  line-height: 0; }

.lh_1 {
  line-height: 1; }

.lh_1x {
  line-height: 100%; }

.cir_d {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  overflow: hidden; }

.cir {
  border-radius: 50%;
  overflow: hidden; }

.cir_xs {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden; }

.cir_s {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden; }

.cir_m {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden; }

.cir_l {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden; }

.cir_xl {
  width: 60px;
  height: 60px;
  border-radius: 50%; }

.cir_xxl {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  overflow: hidden; }

.sqr_d {
  width: 44px;
  height: 44px; }

.sqr_s {
  width: 33px;
  height: 33px; }

.sqr_35 {
  width: 35px;
  height: 35px; }

.sqr_m {
  width: 40px;
  height: 40px; }

.sqr_44 {
  width: 44px;
  height: 44px; }

.sqr_l {
  width: 50px;
  height: 50px; }

.sqr_xl {
  width: 60px;
  height: 60px; }

.sqr_xxl {
  width: 90px;
  height: 90px; }

.zi_1, .z_1 {
  z-index: 1; }

.zi_2, .z_2 {
  z-index: 2; }

.zi_3, .z_3 {
  z-index: 3; }

.z_1000 {
  z-index: 1000; }

.h_xs {
  height: 24px; }

.h_xsr {
  height: .24rem; }

.h_s {
  height: 34px; }

.h_sr {
  height: .34rem; }

.h_m {
  height: 40px; }

.h_mr {
  height: .4rem; }

.h_l {
  height: 50px; }

.h_lr {
  height: .5rem; }

.h_xl {
  height: 60px; }

.h_xlr {
  height: .6rem; }

.h_bar {
  height: 44px; }

.h_full, .h_1x {
  height: 100%; }

.h_25 {
  height: 25px; }

.h_25r {
  height: .25rem; }

.h_30 {
  height: .30rem; }

.h_30r {
  height: .30rem; }

.mh_90 {
  min-height: 90px; }

.w_0 {
  width: 0px; }

.w_s {
  width: 30px; }

.w_m {
  width: 40px; }

.w_l {
  width: 50px; }

.w_xl {
  width: 60px; }

.w_bar {
  width: 44px; }

.w_full, .w_1x {
  width: 100%; }

.rot_45 {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.rot_90 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg); }

.rot_135 {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg); }

.rot_180 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }

.rot_225 {
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg); }

.rot_270 {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg); }

.rot-45 {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.rot-90 {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg); }

.br_a, .br_aaa {
  position: relative; }

.br_a:after, .br_aaa:after {
  position: relative;
  content: '';
  display: block;
  transform: scale(0.5);
  width: 0px;
  height: 200%;
  border-right: 1px solid #ff6f00;
  transform-origin: 0% 0%;
  position: absolute;
  top: 0px;
  box-sizing: border-box;
  z-index: 0;
  pointer-events: none;
  right: 0; }

.br_aaa:after {
  border-right-color: #aaa; }

.br_2 {
  border-radius: 2px; }

.br_4 {
  border-radius: 4px; }

.br_6 {
  border-radius: 6px; }

.bd_b, .bd_t, .bd_r, .bd_l, .bd_tl, .bd_tr, .bd_tbr, .bd_tbl, .bd_tb, .bd_blr, .bd_bl, .bd_br, .bd_lr, .bd_all, .bd_c {
  position: relative; }

.bd_b:after, .bd_t:after, .bd_r:after, .bd_l:after, .bd_tl:after, .bd_tr:after, .bd_tbr:after, .bd_tbl:after, .bd_tb:after, .bd_blr:after, .bd_bl:after, .bd_br:after, .bd_lr:after, .bd_all:after, .bd_c:after {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  right: -100%;
  left: 0;
  bottom: -100%;
  pointer-events: none;
  z-index: 1;
  transform: scale(0.5);
  transform-origin: 0% 0%;
  border: 1px solid #ddd;
  border-radius: 0; }

.bd_b:after {
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0; }

.bd_t:after {
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0; }

.bd_l:after {
  border-bottom-width: 0;
  border-top-width: 0;
  border-right-width: 0; }

.bd_r:after {
  border-bottom-width: 0;
  border-top-width: 0;
  border-left-width: 0; }

.bd_tb:after {
  border-left-width: 0;
  border-right-width: 0; }

.bd_tbr:after {
  border-left-width: 0; }

.bd_tbl:after {
  border-right-width: 0; }

.bd_tr:after {
  border-bottom-width: 0;
  border-left-width: 0; }

.bd_tl:after {
  border-bottom-width: 0;
  border-right-width: 0; }

.bd_blr:after {
  border-top-width: 0; }

.bd_bl:after {
  border-top-width: 0;
  border-right-width: 0; }

.bd_br:after {
  border-top-width: 0;
  border-left-width: 0; }

.bd_lr:after {
  border-top-width: 0;
  border-bottom-width: 0; }

.bd_c:after {
  right: 0;
  border-bottom-width: 0;
  border-top-width: 0;
  border-left-width: 0; }

.bd_0 {
  border: 0; }

.t_3 {
  transition: all 300ms ease-out 0ms; }

.t_2 {
  transition: all 200ms ease-out 0ms; }

.op_0 {
  opacity: 0; }

.app-anim-fade-in {
  opacity: 1;
  transition: opacity 300ms ease-in 0ms; }

.app-viewport, .app-photoviewer {
  position: absolute;
  overflow: hidden;
  width: 100%;
  background: #fff;
  bottom: 0px;
  top: 0px;
  right: 0;
  left: 0;
  z-index: 1; }

.app-view, .app-skeleton {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  background: #f4f4f4;
  overflow: hidden;
  box-shadow: -3px 2px 5px rgba(49, 60, 75, 0.35);
  z-index: 0; }

.app-prevent-click:before, .app-view:before {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000; }

.app-view.app-view-actived {
  z-index: 4; }

.app-view.app-view-actived:before {
  display: none; }

.app-view.app-view-actived .app-main {
  z-index: 100; }

.app-view.transparent {
  background: rgba(0, 0, 0, 0);
  border: 0;
  box-shadow: none; }

.app-skeleton {
  z-index: 5; }

.app-scrollview {
  overflow: hidden;
  -webkit-overflow-scrolling: touch; }

.app-header {
  height: 44px;
  color: #333;
  background: #f9f9f9;
  position: relative;
  z-index: 200;
  background-clip: border-box; }

.app-header:after {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  right: -100%;
  left: 0;
  bottom: -100%;
  pointer-events: none;
  z-index: 1;
  transform: scale(0.5);
  transform-origin: 0% 0%;
  border: 1px solid #ddd;
  border-radius: 0px;
  border-top: 0;
  border-left: 0;
  border-right: 0; }

.app-header.app-header-with-bg:after {
  display: none; }

.app-header-con {
  width: 100%;
  height: 100%;
  z-index: 0; }

.app-header-title, .app-header-tab {
  margin: 0 1rem;
  text-align: center;
  width: 1.75rem; }

.app-header-tab {
  position: relative;
  height: 100%; }

.app-header-tab .curr {
  color: #ff6f00; }

.app-header-tab-cursor {
  position: absolute;
  height: 2px;
  background: #ff6f00;
  bottom: 0;
  left: 0px;
  width: 50px; }

.app-header-left {
  height: 100%;
  z-index: 1; }

.app-header-right {
  z-index: 1;
  height: 100%; }

.app-header-right .icon {
  padding-right: 10px;
  padding-left: 10px;
  text-align: center; }

.app-header-right .icon:last-child {
  border-right: 5px solid transparent; }

.app-header-back {
  height: 100%;
  width: 44px;
  text-align: center; }

.app-header-back .icon-back {
  color: #333;
  font-size: 26px; }

.app-header-search {
  position: relative;
  height: 32px;
  padding-left: 5px;
  width: 80%;
  margin-left: 13%;
  border-radius: 16px;
  background: #fff; }

.app-header-search:before {
  color: #999;
  margin: 0 0 0 5px;
  font-size: 18px; }

.app-header-search:after {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  right: -100%;
  left: 0;
  bottom: -100%;
  pointer-events: none;
  z-index: 1;
  transform: scale(0.5);
  transform-origin: 0% 0%;
  border: 1px solid #bdbdbd;
  border-radius: 32px; }

.app-header-search input {
  font-size: 14px; }

.app-header-search input::-webkit-input-placeholder {
  font-size: 14px;
  overflow: visible; }

.app-header-search.icon-search:before {
  position: relative; }

.app-android .app-header-search.icon-search:before {
  top: 0; }

.app-header-primary {
  background: #ff6f00;
  color: #fff; }

.app-main, .app-subpage, .app-main-iframe {
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0px;
  top: 0px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1; }

.app-scroll-y {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; }

[app-fixed-onscroll="hidden"], [app-fixed-clone="hidden"] {
  opacity: 0;
  pointer-events: none;
  position: absolute; }

[app-fixed] {
  opacity: 1 !important;
  position: absolute;
  z-index: 1000;
  pointer-events: auto; }

.app-main {
  overflow: visible; }
  .app-main > :first-child[app-fixed-onscroll="hidden"], .app-main > :first-child[app-fixed-clone="hidden"] {
    position: static;
    opacity: 1 !important;
    pointer-events: auto; }

.app-footer {
  z-index: 1000;
  height: 50px;
  position: absolute;
  bottom: 0;
  width: 100%; }

.app-header ~ .app-main, .app-header ~ .app-main-iframe {
  top: 44px; }

.app-footer ~ .app-main, .app-footer ~ .app-main-iframe {
  bottom: 50px; }

.app-main-iframe {
  width: 100%; }

.app-main-iframe iframe {
  width: 100%;
  height: 100%;
  margin-bottom: -10px; }

.app-main > .app-scroller-container {
  min-height: 100%;
  margin-bottom: 1px;
  overflow: hidden; }

.app-header {
  background-size: 100% auto;
  background-position-y: bottom; }

.app-fix-statusbar .app-header {
  border-top: 20px solid transparent;
  height: 64px; }

.app-fix-statusbar .app-header ~ .app-main, .app-fix-statusbar .app-header ~ .app-main-iframe {
  top: 64px; }

.app-fix-statusbar .app-header-bottom ~ .app-main, .app-fix-statusbar .app-header-bottom ~ .app-main-iframe {
  top: 102px; }

.app-android .app-header {
  height: 48px; }

.app-android .app-header ~ .app-main, .app-android .app-header ~ .app-main-iframe {
  top: 48px; }

.app-android .app-header-bottom ~ .app-main, .app-android .app-header-bottom ~ .app-main-iframe {
  top: 86px; }

.app-header ~ [app-fixed] {
  top: 44px;
  z-index: 100; }

.app-fix-statusbar .app-header ~ [app-fixed] {
  top: 64px;
  z-index: 100; }

.app-android .app-header ~ [app-fixed] {
  top: 48px;
  z-index: 100; }

.app-header ~ .app-underhead {
  position: absolute;
  top: 44px; }

.app-fix-statusbar .app-header ~ .app-underhead {
  top: 64px; }

.app-android .app-header ~ .app-underhead {
  top: 48px; }

.app-header-transparent {
  background-color: transparent;
  border-bottom: 0; }

.app-header-transparent ~ .app-main, .app-fix-statusbar .app-header-transparent ~ .app-main, .app-android .app-header-transparent ~ .app-main {
  top: 0; }

.app-header-transparent:after {
  display: none; }

.app-load-more {
  height: 34px;
  font-size: 12px;
  margin: 0px 15px 0 15px; }
  .app-load-more p {
    color: #999;
    padding: 0 15px; }

/* toast */
.app-toast {
  width: 60%;
  position: fixed;
  left: 20%;
  padding: 15px 8px;
  font-size: 16px;
  line-height: 18px;
  top: 50%;
  background: rgba(0, 0, 0, 0.8);
  color: #ffffff;
  text-align: center;
  z-index: 2003;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(49, 60, 75, 0.35);
  overflow: hidden;
  transition: all 200ms ease-out 0ms;
  transform: scale(0.2, 0.2);
  opacity: .2;
  word-wrap: break-word; }

.app-toast-show {
  transform: scale(1, 1);
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.65); }

.app-toast-hide {
  transform: scale(0.2, 0.2);
  opacity: .2; }

/* 轮播 */
.app-slider {
  position: relative;
  overflow: hidden;
  transform: translate(0px, 0px) translateZ(0); }

.app-slider-con {
  background: #fff;
  width: 200%;
  position: relative;
  z-index: 1;
  margin-left: 0; }

.app-slider-con:after {
  clear: both;
  content: '';
  display: block;
  font-size: 0;
  height: 0; }

.app-slider-item {
  width: 3.75rem;
  text-align: center;
  background: #fff; }

.app-slider-nav {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 25px;
  line-height: 25px;
  z-index: 2;
  text-align: center;
  background: rgba(0, 0, 0, 0); }

.app-slider-nav-item {
  display: inline-block;
  height: 6px;
  width: 6px;
  background: rgba(0, 0, 0, 0.3);
  margin: 2px 5px;
  border-radius: 4px;
  font-size: 0;
  vertical-align: -4px; }

.app-slider-nav-item.curr {
  background: #ff4500; }

.app-slider img {
  width: 100%;
  display: block; }

/* 图片浏览 */
.app-photoviewer {
  opacity: 0;
  background: #000;
  z-index: 3000;
  overflow: hidden; }

.app-photoviewer.show {
  opacity: 1; }

.app-photoviewer-con {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative; }

.app-photoviewer-item {
  flex: 1;
  width: 0px;
  height: 100%;
  overflow: hidden; }

.app-photoviewer-item-con {
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  padding-right: 20px;
  padding-bottom: 20px;
  position: relative; }

.app-photoviewer-item p {
  position: relative;
  width: 100%;
  height: 100%; }

.app-photoviewer-item img {
  max-width: 3.75rem;
  height: auto;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%; }

.app-photoviewer-after {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0; }

.app-photoviewer-indicator {
  position: relative;
  bottom: 50px;
  width: 100%; }

/* 弹框 */
.app-modal {
  display: none; }

.app-popup-mask {
  position: absolute;
  z-index: 2000;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  opacity: 0;
  display: none;
  transition: all 300ms ease-out 0ms;
  background: rgba(0, 0, 0, 0.5); }

.app-popup-mask.show {
  display: block;
  opacity: 1; }

.app-popup-container {
  position: absolute;
  z-index: 2001; }

.app-popup-style-scale {
  overflow: hidden;
  top: 50%;
  left: 50%;
  width: 80%;
  transform: translate(-50%, -50%) translateZ(0);
  border-radius: 6px; }

.app-popup-container-scale {
  border-radius: 6px;
  padding-top: 10px;
  min-height: 50px;
  background: #fff;
  opacity: 0;
  transition: all 300ms ease-out 0ms;
  transform-origin: 50% 50%;
  transform: scale(0.3); }

.app-popup-container.show .app-popup-container-scale {
  opacity: 1;
  transform: scale(1); }

.app-popup-style-up {
  bottom: 0;
  left: 0;
  right: 0; }

.app-popup-style-fade, .app-popup-container-fade {
  position: relative; }

.app-popup-style-fade {
  opacity: 0;
  transition: opacity 300ms ease-out 0ms; }

.app-popup-style-fade.show {
  opacity: 1; }

.app-popup-style-dropdown {
  opacity: 0;
  transform-origin: 100% 0%;
  transform: scale(0.5);
  position: relative;
  top: 0;
  right: 0;
  transition: all 200ms ease-out 0ms; }

.app-popup-style-dropdown.show {
  opacity: 1;
  transform: scale(1); }

.app-popup-style-dropdown + .app-popup-mask {
  background: rgba(0, 0, 0, 0); }

.app-popup-container-up {
  border-radius: 0px;
  min-height: 50px;
  background: #fff;
  opacity: 0;
  transition: all 300ms ease-out 0ms;
  transform-origin: 0% 0%;
  transform: translate(0%, 100%) translateZ(0); }

.app-popup-container.show .app-popup-container-up {
  opacity: 1;
  transform: translate(0%, 0%) translateZ(0); }

.app-popup-title {
  text-align: center;
  margin: 0 20px 0 20px;
  font-weight: bold; }

.app-popup-content {
  margin: 20px;
  line-height: 22px;
  word-break: break-all; }

.app-popup-action {
  text-align: center;
  position: relative;
  display: flex; }

.app-popup-action:before {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  right: -100%;
  left: 0;
  bottom: -100%;
  pointer-events: none;
  z-index: 1;
  transform: scale(0.5);
  transform-origin: 0% 0%;
  border: 1px solid #e0e0e0;
  border-radius: 0;
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  height: 1px; }

.app-popup-action .btn {
  color: #fff;
  border: 0px;
  height: 44px;
  display: block;
  flex: 1;
  font-size: .15rem;
  width: 0;
  background: #ff6f00;
  color: #333;
  background: white;
  position: relative; }

.app-popup-action .btn:nth-child(1) {
  border-bottom-left-radius: 6px; }

.app-popup-action .btn:nth-child(1):before {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  right: -100%;
  left: 0;
  bottom: -100%;
  pointer-events: none;
  z-index: 1;
  transform: scale(0.5);
  transform-origin: 0% 0%;
  border: 1px solid #e0e0e0;
  border-radius: 0;
  border-bottom-width: 0;
  border-left-width: 0;
  border-top-width: 0; }

.app-popup-action .btn:nth-child(2) {
  color: #FF6F00; }

.app-popup-action .btn:last-child {
  border-bottom-right-radius: 6px; }

/* 选项卡 */
.app-tab-head-wrap {
  background-color: #fff; }

.app-tab-head {
  height: 46px; }

.app-tab-head-cursor {
  position: absolute;
  height: 2px;
  background: #ff6f00;
  bottom: 0;
  left: 0px;
  width: 50px; }

.app-tab-head > ul {
  height: 100%; }

.app-tab-head .curr .app-tab-title {
  color: #ff6f00; }

.app-tab-body {
  top: 46px; }

.app-tab-body-inner .app-tab-pane {
  -webkit-box-flex: 1;
  flex: 1;
  height: 100%;
  width: 0px;
  position: relative; }

/* 开关 */
.app-switch {
  height: 24px;
  width: 44px;
  border-radius: 13px;
  background: #eee;
  transition: background 100ms ease-in 0ms; }

.app-switch::before {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  display: inline-block;
  height: 38px;
  width: 38px;
  border: 1px solid #ddd;
  border-radius: 44px;
  margin: 2px;
  background: #fff;
  transform: scale(0.5);
  transform-origin: 0% 0%;
  transition: left 100ms ease-out 0ms; }

.app-switch::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  right: -100%;
  bottom: -100%;
  border: 1px solid #ddd;
  border-radius: 48px;
  transform: scale(0.5);
  transform-origin: 0% 0%; }

.app-switch-checked {
  background: #0079FF; }

.app-switch-checked::before {
  border: 0;
  height: 40px;
  width: 40px;
  left: 20px; }

.app-switch-checked::after {
  display: none; }

.app-switch-disabled {
  opacity: .5;
  background-color: #f1f1f1; }

.app-switch-disabled::before {
  background-color: #eee; }

.app-loader {
  top: 50%;
  left: 50%;
  margin: -16px 0 0 -16px;
  position: absolute;
  width: 32px;
  height: 32px;
  text-indent: -10000px;
  background: url(./images/loading@2x.png) center center no-repeat;
  background-size: 80% 80%;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: step-start;
  z-index: 10000;
  opacity: 0;
  transition: opacity 300ms ease-out 0ms;
  pointer-events: none;
  border-radius: 50%;
  animation-name: app-loading-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: step-start; }

.app-loader.show {
  opacity: 1; }

.app-placeholder {
  height: 14px;
  background: #e7e7e7;
  margin-top: 10px; }

.app-loading {
  height: 40px;
  padding-top: 8px;
  text-align: center; }

.app-loading::after {
  display: inline-block;
  content: '';
  background: url(./images/loading@2x.png);
  width: 25px;
  height: 25px;
  background-size: 100% auto;
  animation-name: app-loading-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: step-start; }

.app-navigation-view {
  position: relative;
  padding-top: 34px; }

.app-navigation-view-bars {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  height: 34px;
  overflow: hidden;
  overflow-x: auto; }

.app-navigation-view-bars > ul {
  height: 100%;
  background: #fff;
  min-width: 3.75rem; }

.app-navigation-view-bar-item {
  float: left;
  height: 20px;
  font-size: 13px;
  margin: 7px 9px;
  line-height: 20px; }

.app-navigation-view-bar-item:not(.curr):first-child {
  margin-left: 18px; }

.app-navigation-view-content {
  height: 100%; }

.app-navigation-view-content-item {
  height: 100%;
  overflow: hidden;
  width: 0px; }

@media screen and (min-width: 375px) {
  .app-navigation-view {
    padding-top: .34rem; }
  .app-navigation-view-bars {
    height: .34rem; }
  .app-navigation-view-bar-item {
    height: .2rem;
    font-size: .13rem;
    margin: .07rem .09rem;
    line-height: .2rem; } }

/* 下拉选择框弹框 */
.app-selector-wrap {
  display: -webkit-box;
  position: absolute;
  z-index: 1000;
  bottom: 0px;
  width: 100%;
  height: 270px;
  transform: translate(0px, 100%);
  transition: all 300ms ease-out 0ms; }

.app-selector-wrap.show {
  transform: translate(0px, 0px); }

.app-selector-wrap.show .app-selector-con > ul {
  transform: translate(0px, 0px); }

.app-selector-bar {
  height: 38px;
  z-index: 3;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  line-height: 38px; }

.app-selector-bar b {
  width: 80px;
  padding-right: 10px;
  float: right;
  height: 100%;
  font-weight: normal;
  text-align: right;
  color: #0079FF;
  font-size: 16px; }

.app-selector {
  display: -webkit-box;
  background: #fff;
  text-align: center;
  height: 232px;
  position: relative;
  z-index: 1;
  margin: 38px 0 0 0;
  width: 100%; }

.app-selector-item {
  -webkit-box-flex: 1;
  width: 0;
  overflow: hidden; }

.app-selector-item:nth-child(n+2) {
  border-left: 1px solid #ddd; }

.app-selector:before {
  content: '';
  display: block;
  background: -webkit-linear-gradient(top, white 5%, rgba(255, 255, 255, 0) 50%, white 95%);
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0px;
  z-index: 2;
  pointer-events: none; }

.app-selector-con {
  position: relative;
  z-index: 1;
  margin-top: -6px; }

.app-selector:after {
  content: '';
  display: block;
  margin-top: -11px;
  position: absolute;
  left: 0;
  top: 50%;
  height: 64px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  width: 200%;
  z-index: 100;
  pointer-events: none;
  transform: scale(0.5);
  transform-origin: 0% 0%; }

.app-selector-con > ul > li {
  height: 30px;
  font-size: 16px; }

.app-selector-con > ul {
  padding: 116px 0 86px 0;
  transform: translate(0px, 100%);
  transition: all 300ms ease-out 0ms; }

/* 小文字垂直居中 */
.app-smalltext-fix {
  width: 0px;
  overflow: hidden;
  display: inline-block;
  font-size: 12px; }

.app-outside-screen {
  position: absolute !important;
  left: 4rem !important; }

@keyframes app-loading-anim {
  from {
    -webkit-transform: rotate(0deg); }
  8.32% {
    -webkit-transform: rotate(0deg); }
  8.33% {
    -webkit-transform: rotate(30deg); }
  16.65% {
    -webkit-transform: rotate(30deg); }
  16.66% {
    -webkit-transform: rotate(60deg); }
  24.99% {
    -webkit-transform: rotate(60deg); }
  25% {
    -webkit-transform: rotate(90deg); }
  33.32% {
    -webkit-transform: rotate(90deg); }
  33.33% {
    -webkit-transform: rotate(120deg); }
  41.65% {
    -webkit-transform: rotate(120deg); }
  41.66% {
    -webkit-transform: rotate(150deg); }
  49.99% {
    -webkit-transform: rotate(150deg); }
  50% {
    -webkit-transform: rotate(180deg); }
  58.32% {
    -webkit-transform: rotate(180deg); }
  58.33% {
    -webkit-transform: rotate(210deg); }
  66.65% {
    -webkit-transform: rotate(210deg); }
  66.66% {
    -webkit-transform: rotate(240deg); }
  74.99% {
    -webkit-transform: rotate(240deg); }
  75% {
    -webkit-transform: rotate(270deg); }
  83.32% {
    -webkit-transform: rotate(270deg); }
  83.33% {
    -webkit-transform: rotate(300deg); }
  91.65% {
    -webkit-transform: rotate(300deg); }
  91.66% {
    -webkit-transform: rotate(330deg); }
  99.99% {
    -webkit-transform: rotate(330deg); }
  to {
    -webkit-transform: rotate(360deg); } }

@keyframes motion-anim {
  from {
    -webkit-transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(45deg); }
  50% {
    -webkit-transform: rotate(0deg); }
  75% {
    -webkit-transform: rotate(-45deg); }
  to {
    -webkit-transform: rotate(0deg); } }

.app-popup-container, .app-popup-mask {
  position: fixed; }

.cm_checkbox__inner {
  display: block;
  position: relative;
  border: 1px solid #979797;
  border-radius: 2px;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  background-color: #fff;
  z-index: 1;
  transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46), background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46); }
  .cm_checkbox__inner:before {
    box-sizing: content-box;
    content: "";
    border: 0;
    width: 32px;
    height: 32px;
    left: 50%;
    top: 50%;
    margin: -16px 0 0 -16px;
    position: absolute; }
  .cm_checkbox__inner:after {
    box-sizing: content-box;
    content: "";
    border: 1px solid #fff;
    border-left: 0;
    border-top: 0;
    height: 7px;
    position: absolute;
    left: 7px;
    top: 1px;
    -ms-transform: rotate(45deg) scaleY(0);
    transform: rotate(45deg) scaleY(0);
    width: 4px;
    transition: transform 0.15s cubic-bezier(0.71, -0.46, 0.88, 0.6) 0.05s;
    -ms-transform-origin: 0 2px;
    transform-origin: 0 2px; }
  .cm_checkbox__inner.is_checked {
    background-color: #ff6f00;
    border-color: #ff6f00; }
    .cm_checkbox__inner.is_checked:after {
      transform: rotate(45deg) scaleY(1); }
  .cm_checkbox__inner.is_disabled {
    background-color: #ddd;
    border-color: #ddd;
    cursor: not-allowed; }
    .cm_checkbox__inner.is_disabled:after {
      border-color: #ddd; }

.cm_checkbox {
  display: inline-block;
  position: relative; }
  .cm_checkbox .cm_checkbox_container,
  .cm_checkbox .cm_checkbox__input,
  .cm_checkbox .cm_checkbox__label {
    pointer-events: none; }
  .cm_checkbox .cm_checkbox__input {
    display: inline-block;
    outline: 0;
    position: absolute;
    vertical-align: middle;
    top: 50%;
    transform: translateY(-50%); }
  .cm_checkbox .cm_checkbox__original {
    opacity: 0;
    outline: 0;
    position: absolute;
    margin: 0;
    width: 0;
    height: 0;
    left: -999px; }
  .cm_checkbox .cm_checkbox__label {
    padding-left: 21px;
    float: left; }

.cm_num__modifier {
  height: 32px; }

.cm_num__modifier button {
  background: #fffffe;
  vertical-align: bottom;
  width: 32px;
  height: 32px;
  color: #666;
  background: #f0f0f0;
  font-size: 19px;
  border: 1px #eee solid; }

.cm_num__modifier button:active {
  background: #b1b1b1; }

.cm_num__modifier input {
  padding: 0;
  width: 50px;
  height: 32px;
  color: #333;
  font-size: 14px;
  text-align: center;
  background: #fff;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-left: none;
  border-right: none; }

.cm_num__modifier .disabled {
  color: #ddd; }

.consult_box {
  position: fixed;
  overflow: hidden;
  right: 24px;
  bottom: 24px;
  z-index: 99;
  border-radius: 8px;
  box-shadow: 0px 12px 48px 16px rgba(0, 0, 0, 0.03), 0px 9px 28px 0px rgba(0, 0, 0, 0.2), 0px 6px 16px -8px rgba(0, 0, 0, 0.1); }
  .consult_box iframe {
    display: block; }

.cm_home_fixed__bar {
  width: 80px;
  height: 250px;
  position: fixed;
  right: 0;
  bottom: 50%;
  transform: translateY(50%);
  z-index: 9; }
  .cm_home_fixed__bar .bar_box {
    cursor: pointer;
    width: 80px;
    padding: 10px 0;
    line-height: 30px;
    height: 83px;
    font-size: 14px;
    background: #3E424C;
    color: #fff;
    border-bottom: 1px solid #f5f5f5;
    text-align: center; }
    .cm_home_fixed__bar .bar_box:last-child {
      border-bottom: none; }

.cm_radio {
  position: relative;
  top: 2px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.15);
  cursor: pointer; }
  .cm_radio.selected {
    border: 1px solid orangered; }
    .cm_radio.selected::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: orangered; }

.cm_progress__list {
  z-index: 2;
  overflow: hidden;
  float: right;
  position: relative;
  overflow: hidden; }
  .cm_progress__list:after {
    content: '';
    display: block;
    position: absolute;
    left: 30px;
    right: 30px;
    top: 15px;
    height: 1px;
    border-bottom: 1px dashed #ccc;
    z-index: 1; }
  .cm_progress__list .progress_item {
    float: left;
    position: relative;
    margin: 0 30px;
    z-index: 3; }
    .cm_progress__list .progress_item:first-child {
      margin-left: 0;
      margin-right: 40px; }
    .cm_progress__list .progress_item:last-child {
      margin-right: 0;
      margin-left: 40px; }
    .cm_progress__list .progress_item.selected .number {
      background: #FF4500;
      background: #999999 linear-gradient(90deg, #ff8d00 0%, #ff6300 100%);
      color: #fff;
      border: 1px solid transparent; }
    .cm_progress__list .progress_item.selected p {
      color: #333; }
    .cm_progress__list .progress_item .number {
      background: white;
      width: 30px;
      height: 30px;
      line-height: 29px;
      border: 1px solid #DEDEDE;
      color: #CCCCCC;
      font-size: 16px;
      border-radius: 15px;
      margin: 0 auto;
      text-align: center;
      position: relative; }
      .cm_progress__list .progress_item .number:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 30px;
        height: 30px;
        z-index: -1;
        transform: scaleX(1.4);
        background: #fff;
        transform-origin: 58% 50%; }
    .cm_progress__list .progress_item p {
      text-align: center;
      font-size: 14px;
      margin-top: 6px;
      color: #999; }

.app-popup-style-scale.cm_popup__confirm {
  width: 480px;
  border-radius: 2px; }
  .app-popup-style-scale.cm_popup__confirm .confirm_box {
    padding: 30px 30px 20px 30px; }
    .app-popup-style-scale.cm_popup__confirm .confirm_box .icon-warn {
      width: 35px;
      float: left;
      color: #FF4500;
      font-size: 20px; }
    .app-popup-style-scale.cm_popup__confirm .confirm_box .icons-warn-rem {
      margin-right: 5px;
      float: left; }
    .app-popup-style-scale.cm_popup__confirm .confirm_box .title {
      font-size: 16px;
      color: #000;
      font-weight: 500;
      float: left;
      width: 380px; }
    .app-popup-style-scale.cm_popup__confirm .confirm_box .content {
      margin: 12px 35px 0; }
    .app-popup-style-scale.cm_popup__confirm .confirm_box .operates {
      margin-top: 30px; }
      .app-popup-style-scale.cm_popup__confirm .confirm_box .operates .operate_btn {
        cursor: pointer;
        float: right;
        margin-left: 8px;
        padding: 0 15px;
        height: 32px;
        text-align: center;
        border-radius: 2px;
        line-height: 32px;
        font-size: 14px; }
        .app-popup-style-scale.cm_popup__confirm .confirm_box .operates .operate_btn.cancel {
          color: #666;
          background: white;
          border: 1px solid rgba(0, 0, 0, 0.15); }
        .app-popup-style-scale.cm_popup__confirm .confirm_box .operates .operate_btn.ok {
          background: #FF6F00;
          color: #fff; }

.cm_steps_horizontal .item {
  position: relative;
  display: inline-block;
  vertical-align: top; }
  .cm_steps_horizontal .item .head {
    position: relative;
    text-align: center;
    font-size: 0; }
    .cm_steps_horizontal .item .head:before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      z-index: 1;
      width: 50%;
      border-top: 1px dashed #ccc; }
    .cm_steps_horizontal .item .head:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      z-index: 1;
      width: 50%;
      border-top: 1px dashed #ccc; }
    .cm_steps_horizontal .item .head .inner {
      position: relative;
      z-index: 2;
      display: inline-block;
      width: 30px;
      height: 30px;
      padding: 5px;
      box-sizing: content-box;
      background: #fff; }
      .cm_steps_horizontal .item .head .inner i {
        display: block;
        height: 100%;
        border-radius: 50%;
        border: 1px solid #ccc;
        font-size: 16px;
        color: #ccc;
        line-height: 28px;
        background-color: #fff; }
  .cm_steps_horizontal .item .main {
    margin: 10px 20px;
    color: #999;
    font-size: 14px; }

.cm_steps_horizontal .item.active .head .inner i {
  color: #fff;
  border-color: transparent;
  background: #ff6300;
  background: linear-gradient(90deg, #ff8d00 0%, #ff6300 100%); }

.cm_steps_horizontal .item.active .main {
  color: #333; }

.cm_steps_horizontal .item.first .head:before {
  display: none; }

.cm_steps_horizontal .item.last .head:after {
  display: none; }

.cm_steps_vertical .item {
  position: relative; }
  .cm_steps_vertical .item .head {
    float: left;
    zoom: 1;
    text-align: center;
    font-size: 0; }
    .cm_steps_vertical .item .head:before {
      content: "";
      position: absolute;
      left: 13px;
      top: 0;
      z-index: 1;
      height: 100%;
      border-left: 1px dashed #ccc; }
    .cm_steps_vertical .item .head .inner {
      position: relative;
      z-index: 2;
      display: inline-block;
      width: 26px;
      height: 26px; }
      .cm_steps_vertical .item .head .inner i {
        display: block;
        height: 100%;
        border-radius: 50%;
        border: 1px solid #ccc;
        font-size: 16px;
        color: #ccc;
        line-height: 24px;
        background-color: #fff; }
  .cm_steps_vertical .item .main {
    padding-left: 15px;
    padding-bottom: 20px;
    color: #999;
    font-size: 14px;
    overflow: hidden; }

.cm_steps_vertical .item.active .head .inner i {
  color: #fff;
  border-color: transparent;
  background: #ff6300;
  background: linear-gradient(90deg, #ff8d00 0%, #ff6300 100%); }

.cm_steps_vertical .item.active .main {
  color: #333; }

.cm_steps_vertical .item.last .head:before {
  display: none; }

.ff_def, body, html, body, html, input, button {
  font-family: '微软雅黑', 'PingFang SC', 'Helvetica Neue', 'Helvetica', 'STHeitiSC-Light', 'Arial', sans-serif; }

body {
  background-color: #fff; }

html, body {
  height: 100%;
  width: auto; }

button[disabled] {
  cursor: default; }

.app-view {
  transform: none !important; }

.app-viewport, .app-photoviewer, .app-view {
  position: relative;
  background-color: #fff;
  width: auto;
  overflow: visible;
  box-shadow: none; }

.md_fixed_wrapper {
  position: fixed;
  right: 0;
  bottom: 200px; }

.app-popup-style-scale {
  transform: translate(-50%, -50%); }

.app-popup-container-up {
  transform: translate(0%, 100%); }

.app-popup-container.show .app-popup-container-up {
  transform: translate(0%, 0%); }

.app-wrapper, .app-wrapper-gray, .app-wrapper-fff {
  width: 100%;
  min-width: 1180px;
  background-color: #fff; }

.app-wrapper-gray {
  background-color: whitesmoke;
  background-size: 100% auto;
  background-repeat: no-repeat; }

.app-main {
  width: 1180px;
  margin: 0px auto;
  position: relative; }

.app-breadcrumbs {
  width: 1180px;
  margin: 0px auto;
  padding: 0 15px;
  height: 40px;
  line-height: 40px;
  background-color: #F8F8F8;
  font-size: 14px; }

.app-breadcrumbs-item {
  float: left;
  color: #666; }

.app-breadcrumbs-arrow {
  float: left;
  padding: 0 10px; }

.app-breadcrumbs-arrow::before {
  content: '';
  border-right: 1px solid #999;
  border-top: 1px solid #999;
  width: 6px;
  height: 6px;
  display: inline-block;
  transform: rotate(45deg);
  vertical-align: 2px; }

.app-breadcrumbs .current {
  width: 200px;
  float: left; }

.app-popup-style-scale {
  width: 400px; }

.app-toast {
  width: 300px;
  left: 50%;
  margin-left: -150px; }

.app-signin {
  width: 3.2rem; }

.app-signin form {
  width: 80%;
  margin: 0 auto; }

.app-signin .mobi:after, .app-signin .pwd:after {
  border-color: #bdbdbd;
  border-radius: 10px; }

.app-signin .btn {
  width: 100%; }

.app-form-item {
  margin-bottom: 15px; }

.app-form-label {
  display: inline-block;
  width: 90px;
  font-size: 14px;
  color: #666;
  margin-left: 3px; }

.app-form-error {
  display: inline-block;
  color: #ff4500;
  font-size: 14px;
  margin-left: 10px; }

.app-form-error i {
  font-size: 14px; }

.app-form-required {
  margin-right: 4px;
  color: #ff4500; }

.app-form-input-wrap {
  display: inline-block;
  vertical-align: middle;
  position: relative; }

.app-form-input {
  width: 250px;
  height: 32px;
  border: 1px solid #ececec;
  border-radius: 2px;
  padding: 0 8px;
  color: #333;
  outline: none;
  font-size: 14px; }

.app-form-input.wide {
  width: 300px; }

.app-form-input.tight {
  width: 80px; }

.app-form-tag {
  margin-right: 10px;
  width: 58px;
  height: 24px;
  line-height: 24px;
  border-radius: 12px;
  border: 1px solid #ccc;
  font-size: 14px;
  background-color: #fff;
  color: #666; }

.app-form-tag.checked {
  background-color: #ff6f00;
  color: #fff;
  border: none; }

.app-global-address-select {
  position: relative;
  display: inline-block;
  border: 1px solid #E0E0E0;
  height: 30px;
  border-radius: 2px; }

.app-global-address-select-con {
  height: 28px;
  font-size: 12px;
  color: #666;
  padding: 0 25px 0 10px; }

.app-global-address-select-con::after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-top: 1px solid #999;
  border-left: 1px solid #999;
  position: absolute;
  right: 8px;
  top: 8px;
  transform: rotate(-135deg); }

.app-global-address-select-modal {
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid #e0e0e0;
  z-index: 10000; }

.app-global-address-select-modal h5 {
  font-size: 12px;
  margin-top: 15px;
  padding-left: 10px;
  padding-bottom: 8px; }

.app-global-address-select-modal .addressList {
  border-bottom: 1px solid #E0E0E0;
  padding: 0 10px 10px 10px; }

.app-global-address-select-modal .addressList li {
  line-height: 25px;
  font-size: 14px;
  color: #999;
  cursor: pointer; }

.app-global-address-select-modal .addressList li.curr {
  color: #ff6f00; }

.app-global-address-select-modal .no-address {
  padding: 15px;
  font-size: 14px;
  color: #666; }

/* 公共地址选择 */
.app-district-dropdown {
  position: relative; }

.app-district-dropdown-main {
  position: relative;
  font-size: 14px;
  height: 32px;
  border: 1px solid #ececec;
  border-radius: 2px;
  padding: 0 20px 0 8px;
  color: #333;
  outline: none;
  line-height: 32px;
  text-align: left;
  min-width: 250px; }

.app-district-dropdown-main .placeholder {
  color: #999; }

.app-district-dropdown-main::after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-top: 1px solid #999;
  border-left: 1px solid #999;
  position: absolute;
  right: 10px;
  top: 8px;
  transform: rotate(-135deg); }

.app-district-dropdown-main.open::after {
  transform: rotate(45deg);
  top: 12px; }

.app-district-dropdown-selector {
  position: absolute;
  z-index: 100;
  width: 420px;
  background-color: #fff;
  border: 1px solid #ececec; }

.app-district-selector {
  width: 400px;
  background-color: #fff;
  border-radius: 2px;
  padding: 0 8px;
  color: #333; }

.app-district-selector .tab li {
  margin-right: 4px;
  font-size: 12px;
  height: 24px;
  line-height: 24px;
  border: 1px solid #ddd;
  border-bottom: 0;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background: #fff;
  position: relative;
  z-index: 1;
  padding-left: 10px;
  padding-right: 24px; }

.app-district-selector .tab li::after {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  border-top: 1px solid #999;
  border-left: 1px solid #999;
  position: absolute;
  right: 10px;
  top: 6px;
  transform: rotate(-135deg); }

.app-district-selector .tab li.curr {
  height: 25px;
  border-color: #FF6F00;
  color: #FF6F00;
  margin-top: 1px; }

.app-district-selector .tab li.curr::after {
  border-color: #FF6F00; }

.app-district-selector .list {
  border-top: 1px solid #ff6f00;
  margin-top: -1px;
  padding-top: 14px;
  padding-bottom: 14px; }

.app-district-selector .list li {
  width: 25%;
  line-height: 24px;
  font-size: 12px;
  cursor: pointer; }

.app-district-selector .list li.curr {
  color: #ff6f00;
  cursor: default; }

.app-district-dropdown-close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 4px 10px; }

.app-select {
  min-width: 160px;
  border: 1px solid #ececec;
  line-height: 32px;
  background: none;
  font-size: 14px; }

.app-page-error {
  padding: 150px 0; }
  .app-page-error img {
    display: block;
    width: 180px;
    height: 175px;
    margin: 50px auto 0; }
  .app-page-error p {
    text-align: center;
    font-size: 48px;
    margin: 10px; }

/* 模块通用 */
.bk_mt {
  margin-top: 10px; }

.bk_mb {
  margin-bottom: 10px; }

.bk_mlr {
  margin-left: 10px;
  margin-right: 10px; }

.bk_pt {
  padding-top: 10px; }

.bk_pb {
  padding-bottom: 10px; }

.bk_plr {
  padding-left: 10px;
  padding-right: 10px; }

.mb_100 {
  margin-bottom: 100px; }

.usercenter-wrapper {
  overflow: hidden; }

.usercenter-nav {
  width: 180px;
  padding: 30px 0 0 30px;
  color: #666;
  background: #fff;
  float: left;
  margin-bottom: -99999px;
  padding-bottom: 99999px; }
  .usercenter-nav section {
    padding-bottom: 40px; }
  .usercenter-nav h1 {
    font-size: 16px;
    font-weight: bold; }
  .usercenter-nav button {
    display: block;
    margin-top: 10px;
    font-size: 14px;
    cursor: pointer; }
    .usercenter-nav button:hover {
      opacity: .7; }
    .usercenter-nav button.active {
      color: #ff6f00; }

.usercenter-title {
  position: relative;
  height: 32px;
  line-height: 32px; }
  .usercenter-title h1 {
    float: left;
    font-size: 20px;
    font-weight: bold;
    color: #666; }
  .usercenter-title .desc {
    margin-left: 10px;
    float: left;
    font-size: 14px;
    font-weight: 400;
    color: #999; }
  .usercenter-title button {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 120px;
    height: 32px;
    border: 1px solid #ff6f00;
    color: #ff6f00;
    font-size: 14px;
    top: 0px;
    cursor: pointer; }

.usercenter-title-seperator {
  position: relative;
  margin-top: 10px;
  margin-bottom: 20px; }
  .usercenter-title-seperator .orange {
    position: absolute;
    left: 0;
    width: 78px;
    height: 7px;
    border-bottom: 2px solid #ff6f00; }
    .usercenter-title-seperator .orange .triangle {
      position: absolute;
      left: 34px;
      bottom: -5px;
      width: 10px;
      height: 10px;
      border-top: 2px solid #ff6f00;
      border-left: 2px solid #ff6f00;
      background: white;
      transform: rotate(45deg); }
  .usercenter-title-seperator .line {
    margin-left: 78px;
    height: 7px;
    border-bottom: 2px solid #ececec; }

.usercenter-content-wrapper {
  margin-left: 200px;
  background-color: #fff;
  width: 980px;
  margin-bottom: -99999px;
  padding-bottom: 99999px; }

.usercenter-content {
  padding: 40px 60px 40px 60px; }

.usercenter-breadcrumb {
  position: relative;
  display: inline-block;
  margin-right: 50px;
  font-size: 18px;
  color: #ff6f00;
  vertical-align: top;
  line-height: 40px; }
  .usercenter-breadcrumb .triangle {
    position: absolute;
    left: 8px;
    width: 20px;
    height: 20px;
    border-top: 2px solid #f5f5f5;
    border-left: 2px solid #f5f5f5;
    background: #f5f5f5;
    transform: rotate(45deg);
    top: 100%;
    margin-top: 23px; }

.co {
  background-color: #fff;
  padding-bottom: 40px; }
  .co .body {
    width: 1180px;
    margin: 0 auto; }
  .co .cs_p {
    cursor: pointer; }
  .co .promotion {
    height: 22px; }
    .co .promotion .prom {
      margin-right: 12px; }
    .co .promotion .tag {
      margin-right: 10px; }
    .co .promotion .time {
      margin-right: 30px; }
    .co .promotion + .promotion {
      margin-top: 10px; }
    .co .promotion.selected .prom {
      color: #ff4500; }

.co_title {
  height: 22px;
  line-height: 22px; }

.co_receivers {
  margin-bottom: 25px; }
  .co_receivers .btn_add {
    width: 182px;
    height: 90px;
    border: 1px solid #cccccc;
    position: relative; }
    .co_receivers .btn_add .content {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -22px;
      margin-left: -35px; }
      .co_receivers .btn_add .content .icon_add {
        width: 21px;
        height: 21px;
        line-height: 16px;
        display: inline-block;
        border: solid 2px #ff4500;
        color: #ff4500;
        border-radius: 50%;
        margin-bottom: 4px;
        font-weight: bold; }
  .co_receivers .receiver {
    width: 280px;
    height: 130px;
    padding: 12px 15px 10px;
    border: solid 1px #ccc; }
    .co_receivers .receiver .title_wrap {
      padding-bottom: 8px;
      border-bottom: dashed 1px #ccc; }
      .co_receivers .receiver .title_wrap .title {
        height: 22px;
        line-height: 22px; }
    .co_receivers .receiver .address {
      line-height: 17px;
      max-height: 34px; }
    .co_receivers .receiver .bottom {
      left: 0;
      bottom: 10px; }
      .co_receivers .receiver .bottom .btn_modify {
        color: #ff4500; }
    .co_receivers .receiver .img_selected {
      position: absolute;
      bottom: -1px;
      right: -1px;
      width: 40px;
      height: 40px; }
    .co_receivers .receiver.selected {
      border: solid 4px #ff4500;
      background-color: #fff9e7; }
    .co_receivers .receiver + .receiver {
      margin-left: 20px; }
    .co_receivers .receiver:nth-of-type(4n + 1) {
      margin-left: 0px; }
  .co_receivers .btn_show_all {
    display: inline-block;
    color: #ff4500; }
    .co_receivers .btn_show_all .icon-down {
      position: relative;
      top: 1px; }

.co_ci {
  margin-bottom: 27px; }
  .co_ci .items_header {
    height: 43px;
    line-height: 43px;
    border-top: solid 1px #ccc; }
  .co_ci .cols .info {
    width: 448px;
    padding-left: 20px;
    float: left; }
  .co_ci .cols .spec {
    width: 245px;
    float: left; }
  .co_ci .cols .single_price {
    width: 137px;
    float: left; }
  .co_ci .cols .count {
    width: 172px;
    float: left; }
  .co_ci .cols .total {
    width: 157px;
    float: left; }
  .co_ci .seller_block > .title {
    background-color: #fcfcfc;
    height: 50px;
    padding: 15px 20px 16px;
    border: solid 1px #ececec; }
  .co_ci .seller_block .item {
    padding: 20px 0;
    border: solid 1px #ececec;
    border-top: none; }
    .co_ci .seller_block .item .info {
      margin-top: 7px; }
      .co_ci .seller_block .item .info .img_wrap {
        position: relative;
        width: 80px;
        height: 80px; }
        .co_ci .seller_block .item .info .img_wrap .img {
          width: 80px;
          height: 80px; }
        .co_ci .seller_block .item .info .img_wrap .mask {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0; }
          .co_ci .seller_block .item .info .img_wrap .mask .mask_content {
            width: 40px;
            height: 40px;
            line-height: 40px;
            position: absolute;
            left: 50%;
            right: 50%;
            margin-top: 20px;
            margin-left: -20px;
            background-color: rgba(0, 0, 0, 0.5);
            text-align: center;
            color: #fff;
            border-radius: 50%;
            font-size: 11px; }
            .co_ci .seller_block .item .info .img_wrap .mask .mask_content.oos {
              line-height: initial;
              padding-top: 4px; }
      .co_ci .seller_block .item .info > .title {
        width: 250px;
        line-height: 20px;
        max-height: 60px; }
    .co_ci .seller_block .item .spec {
      margin-top: 7px; }
      .co_ci .seller_block .item .spec .line {
        width: 190px; }
      .co_ci .seller_block .item .spec .line + .line {
        margin-top: 4px; }
    .co_ci .seller_block .item .single_price {
      margin-top: 7px; }
    .co_ci .seller_block .item .count .piece {
      margin-top: 7px; }
    .co_ci .seller_block .item .total {
      margin-top: 7px; }
  .co_ci .seller_block + .seller_block {
    margin-top: 15px; }
  .co_ci .check {
    background-color: #fff9e7;
    padding: 14px 20px 18px;
    border: 1px solid #ececec;
    border-top: none; }
    .co_ci .check .coupons {
      margin-bottom: 13px; }
    .co_ci .check .bottom .seller_remark .remark_wrap {
      width: 400px; }
      .co_ci .check .bottom .seller_remark .remark_wrap .remark {
        height: 70px;
        border-radius: 2px 0px 0px 2px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        padding: 4px 10px; }
        .co_ci .check .bottom .seller_remark .remark_wrap .remark::-webkit-input-placeholder {
          color: rgba(0, 0, 0, 0.25); }
        .co_ci .check .bottom .seller_remark .remark_wrap .remark:-moz-placeholder {
          /* Firefox 18- */
          color: rgba(0, 0, 0, 0.25); }
        .co_ci .check .bottom .seller_remark .remark_wrap .remark::-moz-placeholder {
          /* Firefox 19+ */
          color: rgba(0, 0, 0, 0.25); }
        .co_ci .check .bottom .seller_remark .remark_wrap .remark:-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.25); }
      .co_ci .check .bottom .seller_remark .remark_wrap .count {
        position: absolute;
        right: 10px;
        bottom: 5px;
        color: rgba(0, 0, 0, 0.25); }
    .co_ci .check .bottom .sum {
      margin-top: 30px; }
      .co_ci .check .bottom .sum .line .right {
        display: inline-block;
        width: 111px; }
      .co_ci .check .bottom .sum .line + .line {
        margin-top: 4px; }
      .co_ci .check .bottom .sum .total .count {
        color: #ff4500; }
      .co_ci .check .bottom .sum .total .symbol {
        color: #ff4500; }
      .co_ci .check .bottom .sum .total .money {
        font-size: 18px;
        color: #ff4500; }

.co_payment {
  margin-bottom: 25px; }
  .co_payment .method {
    width: 100px;
    height: 32px;
    line-height: 32px;
    border: 1px solid #cccccc; }
    .co_payment .method .img_selected {
      position: absolute;
      right: 0px;
      bottom: 0px;
      width: 20px;
      height: 20px; }
    .co_payment .method.selected {
      border: 1px solid orangered; }
    .co_payment .method + .method {
      margin-left: 10px; }

.co_cc {
  margin-bottom: 25px; }

.co_rp {
  margin-bottom: 25px; }

.co_bottom {
  border-top: 1px solid #ccc;
  padding-top: 25px;
  margin-bottom: 25px; }
  .co_bottom .line .left {
    color: #999; }
  .co_bottom .line .right {
    display: inline-block;
    width: 111px; }
  .co_bottom .total {
    background: #eeeeee;
    padding: 20px; }
    .co_bottom .total .count {
      color: #ff4500; }
    .co_bottom .total .price {
      margin-bottom: 13px; }
      .co_bottom .total .price .symbol {
        font-size: 22px;
        color: #ff4500; }
      .co_bottom .total .price .money {
        font-size: 32px;
        color: #ff4500; }
  .co_bottom .btn_submit {
    width: 180px;
    height: 50px;
    line-height: 50px;
    font-size: 22px;
    background-color: orangered; }
    .co_bottom .btn_submit.disabled {
      background-color: #ccc; }

.full-white {
  width: 100%;
  overflow: hidden;
  background: #fff; }

.w {
  width: 1180px;
  margin: 0 auto; }

.cart-main .cart_list_err {
  padding: 0 0 100px; }
  .cart-main .cart_list_err img {
    display: block;
    width: 180px;
    height: 175px;
    margin: 50px auto 0; }
  .cart-main .cart_list_err button {
    cursor: pointer;
    display: block;
    width: 100px;
    height: 30px;
    background: orangered;
    border-radius: 4px;
    line-height: 30px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    margin: 20px auto; }

.cart-main .tb_con {
  width: 100%;
  border-bottom: 3px solid #eee; }

.cart-main .tab_item {
  cursor: pointer;
  margin-bottom: -3px;
  float: left;
  width: 120px;
  height: 46px;
  line-height: 46px;
  font-size: 14px;
  font-weight: 450;
  border-bottom: 3px solid #eee;
  text-align: center; }
  .cart-main .tab_item span {
    color: #FF4500; }
  .cart-main .tab_item.on {
    color: #FF4500;
    border-bottom: 3px solid #FF4500; }

.cart-main .invalid_list .cart_item_box {
  background: #ECECEC;
  border: 1px solid #f1f1f1; }

.cart-main .invalid_list .invalid_line {
  border: 1px solid #ececec;
  padding: 10px 8px;
  background: #fff; }
  .cart-main .invalid_list .invalid_line .icons-invalid {
    vertical-align: 1px; }
  .cart-main .invalid_list .invalid_line span {
    line-height: 30px; }
  .cart-main .invalid_list .invalid_line .empty_btn {
    float: right;
    cursor: pointer;
    border: 1px solid #DEDEDE;
    width: 120px;
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
    text-align: center;
    color: #666;
    font-size: 14px; }

.cart-main .store_line {
  height: 50px;
  border: 1px solid #ECECEC;
  background: #fcfcfc;
  padding-left: 10px;
  line-height: 50px; }
  .cart-main .store_line .store_name {
    float: left;
    color: #333;
    font-size: 14px;
    margin-left: 10px;
    line-height: 48px; }

.cart-main .cart_item_box {
  min-height: 120px;
  padding: 20px 40px 20px 10px;
  border: 1px solid #ececec;
  border-top: none; }
  .cart-main .cart_item_box .img_box {
    margin-left: 11px;
    width: 80px;
    height: 80px;
    border-radius: 6px;
    margin-right: 6px;
    position: relative;
    cursor: pointer; }
    .cart-main .cart_item_box .img_box img {
      width: 80px;
      height: 80px; }
  .cart-main .cart_item_box .mask {
    left: 10px;
    top: 10px;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    transform: rotate(-20deg);
    transform-origin: 50% 50%;
    background: rgba(0, 0, 0, 0.6);
    line-height: 60px; }
    .cart-main .cart_item_box .mask.low_l {
      top: 60px;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 0;
      transform: rotate(0deg);
      width: 100%;
      height: 20px;
      line-height: 20px;
      font-size: 12px; }
  .cart-main .cart_item_box .item_check {
    margin-top: 30px; }
  .cart-main .cart_item_box .item_name {
    margin-top: 10px;
    width: 310px;
    padding-right: 50px;
    padding-left: 10px;
    cursor: pointer; }
  .cart-main .cart_item_box .spec {
    width: 190px;
    font-size: 14px;
    color: #999; }
    .cart-main .cart_item_box .spec p {
      margin-top: 8px; }
  .cart-main .cart_item_box .price, .cart-main .cart_item_box .priceTotal {
    margin-top: 5px;
    width: 180px;
    text-align: center;
    font-size: 16px;
    color: #333;
    font-weight: bold; }
  .cart-main .cart_item_box .priceTotal {
    color: #FF4500; }
  .cart-main .cart_item_box .operate {
    margin-top: 6px;
    font-size: 14px;
    color: #333;
    cursor: pointer; }
  .cart-main .cart_item_box.checked {
    background: #FFF9E7; }

.cart-main .cart_bottom_bar {
  margin-top: -1px;
  height: 80px;
  background: #fff;
  border: 1px solid #ececec;
  padding: 0 15px 0 10px;
  line-height: 80px; }
  .cart-main .cart_bottom_bar .delete_text {
    float: left;
    margin-left: 20px;
    cursor: pointer; }
  .cart-main .cart_bottom_bar .choosen_info {
    margin-top: 16px;
    float: left;
    line-height: 24px;
    font-size: 14px;
    color: #333; }
    .cart-main .cart_bottom_bar .choosen_info span {
      margin: 0 3px;
      font-weight: bold;
      color: #FF4500; }
  .cart-main .cart_bottom_bar .price_box {
    float: left;
    height: 80px;
    margin-left: 35px;
    margin-right: 40px;
    position: relative;
    overflow: hidden; }
    .cart-main .cart_bottom_bar .price_box .price_area {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      line-height: 24px;
      font-size: 14px;
      color: #333;
      text-align: right; }
      .cart-main .cart_bottom_bar .price_box .price_area .money {
        font-size: 18px;
        color: #FF4500;
        font-weight: bold; }
        .cart-main .cart_bottom_bar .price_box .price_area .money span {
          font-size: 14px; }
      .cart-main .cart_bottom_bar .price_box .price_area .promo {
        line-height: 22px; }
  .cart-main .cart_bottom_bar .checkout_btn {
    float: right;
    margin-top: 15px;
    width: 180px;
    height: 50px;
    border-radius: 4px;
    background: #FF4500;
    color: #fff;
    font-size: 22px;
    line-height: 50px;
    text-align: center;
    font-weight: bold;
    cursor: pointer; }

.cart-main .cart-thead {
  display: inline-block;
  display: block;
  height: 44px;
  line-height: 44px;
  margin: 0 0 10px;
  border-top: 0;
  position: relative; }

.cart-main .yscheckbox {
  float: none;
  position: relative;
  z-index: 5;
  vertical-align: middle;
  _vertical-align: -1px;
  margin: 0 3px 0 0;
  padding: 0; }

.cart-main .cart-thead:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden; }

.cart-main .cart-column {
  float: left; }

.cart-main .cart-thead .t-checkbox {
  height: 44px;
  line-height: 44px;
  width: 133px;
  padding-left: 11px; }

.cart-main .cart-thead .t-goods {
  width: 300px; }

.cart-main .cart-thead .t-props {
  width: 190px;
  height: 32px; }

.cart-main .cart-thead .t-price {
  width: 180px;
  text-align: center; }

.cart-main .cart-thead .t-quantity {
  width: 114px;
  text-align: center; }

.cart-main .cart-thead .t-sum {
  width: 180px;
  text-align: center; }

.cart-main .cart-thead .t-action {
  width: 75px; }

.checkout_pop {
  width: 480px;
  border-radius: 2px; }
  .checkout_pop .app-popup-container-scale {
    padding-top: 0;
    border-radius: 2px; }
  .checkout_pop .cart_accountpop .title {
    padding: 0 30px 0 20px;
    height: 56px;
    font-weight: 500;
    border-bottom: 1px solid #EEE; }
    .checkout_pop .cart_accountpop .title p {
      float: left;
      line-height: 56px;
      font-size: 16px;
      color: #000; }
    .checkout_pop .cart_accountpop .title i {
      display: block;
      float: right;
      cursor: pointer;
      font-size: 18px;
      line-height: 56px; }
  .checkout_pop .cart_accountpop .list {
    padding: 0 20px; }
    .checkout_pop .cart_accountpop .list li {
      position: relative;
      border-bottom: 1px solid #ececec; }
      .checkout_pop .cart_accountpop .list li:last-child {
        border-bottom: 0; }
      .checkout_pop .cart_accountpop .list li .items_info {
        width: 340px; }
      .checkout_pop .cart_accountpop .list li .sub_line {
        margin-top: 8px;
        font-size: 14px; }
        .checkout_pop .cart_accountpop .list li .sub_line span {
          color: #FF4500; }
      .checkout_pop .cart_accountpop .list li button {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        float: right;
        width: 75px;
        height: 32px;
        text-align: center;
        border-radius: 2px;
        line-height: 32px;
        font-size: 14px;
        background: #FF6F00;
        color: #fff; }

.patient-modal {
  width: 650px; }
  .patient-modal .modal_head {
    padding: 0 20px;
    border-bottom: 1px dashed #e0e0e0;
    height: 45px;
    line-height: 35px; }
    .patient-modal .modal_head p {
      font-size: 16px;
      font-weight: bold;
      color: #333;
      margin-right: 10px; }
    .patient-modal .modal_head span {
      color: #999;
      font-size: 14px; }
    .patient-modal .modal_head i {
      cursor: pointer;
      font-size: 20px; }
  .patient-modal .modal_bottom {
    height: 52px;
    border-top: 1px solid #eee;
    padding: 10px 20px; }
    .patient-modal .modal_bottom button {
      height: 32px;
      float: right;
      border-radius: 2px;
      margin-left: 15px;
      font-size: 14px;
      padding: 0 15px;
      cursor: pointer; }
      .patient-modal .modal_bottom button.confirm {
        background: #FF6F00;
        color: #fff; }
      .patient-modal .modal_bottom button.cancel {
        border: 1px solid #e1e1e1; }

.patient-main {
  margin-top: 10px;
  padding: 0 20px; }
  .patient-main ::-webkit-scrollbar {
    width: 5px;
    height: 5px; }
  .patient-main ::-webkit-scrollbar-thumb {
    border-radius: 1em;
    background-color: rgba(50, 50, 50, 0.3); }
  .patient-main ::-webkit-scrollbar-track {
    border-radius: 1em;
    background-color: rgba(50, 50, 50, 0.1); }
  .patient-main .patient_list .full_info {
    width: 560px;
    height: 40px;
    background: #FFF9E7;
    border: 1px solid #ececec;
    border-radius: 4px;
    line-height: 40px;
    color: #666;
    font-size: 14px; }
    .patient-main .patient_list .full_info i {
      color: #FF4500;
      margin-left: 18px; }
  .patient-main .patient_list .add_patient {
    cursor: pointer; }
    .patient-main .patient_list .add_patient:hover {
      color: #FF4500; }
  .patient-main .patient_list .btn_show_all {
    color: #FF4500;
    cursor: pointer; }
  .patient-main .patient_list .list {
    width: 570px;
    height: 80px; }
    .patient-main .patient_list .list.showMore {
      height: 140px;
      width: 575px;
      overflow-y: scroll; }
    .patient-main .patient_list .list .patient {
      width: 180px;
      height: 70px;
      border-radius: 4px;
      border: 1px solid #ccc;
      float: left;
      margin: 10px 10px 0 0;
      cursor: pointer;
      padding: 5px 0 5px 15px;
      position: relative; }
      .patient-main .patient_list .list .patient p {
        line-height: 30px; }
      .patient-main .patient_list .list .patient.selected {
        background: #fff9e7;
        border: 1px solid #FF4500; }
      .patient-main .patient_list .list .patient .img_selected {
        position: absolute;
        bottom: -1px;
        right: -1px;
        width: 40px;
        height: 40px; }

.checkout {
  background-color: #fff;
  padding-bottom: 40px; }
  .checkout .cs_p {
    cursor: pointer; }
  .checkout .body {
    width: 1180px;
    margin: 0 auto; }

.checkout_info {
  padding: 30px;
  background-color: #fff9e7;
  border: 1px solid #ececec; }
  .checkout_info .img_status {
    width: 38px;
    height: 38px; }
  .checkout_info .status {
    width: 413px;
    height: 31px;
    font-size: 24px;
    color: #999; }
  .checkout_info .money {
    color: #ff4500;
    font-size: 20px; }

.checkout_tabs .tab_header .tab {
  width: 120px;
  height: 41px;
  background-color: #efefef;
  border-radius: 2px 2px 0px 0px;
  border: 1px solid #cccccc;
  font-size: 14px;
  line-height: 41px;
  text-align: center;
  color: #666; }
  .checkout_tabs .tab_header .tab.selected {
    background-color: white;
    border-bottom: none; }
  .checkout_tabs .tab_header .tab + .tab {
    margin-left: 5px; }

.checkout_tabs .tab_body {
  padding: 30px 20px;
  background-color: #fff;
  border: solid 1px #cccccc;
  margin-top: -1px; }
  .checkout_tabs .tab_body .qrcode_panel {
    display: inline-block;
    width: 212px;
    text-align: center;
    border-radius: 4px; }
    .checkout_tabs .tab_body .qrcode_panel .header {
      height: 55px;
      line-height: 55px;
      color: #fff;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px; }
      .checkout_tabs .tab_body .qrcode_panel .header .triangle {
        width: 10px;
        height: 10px;
        transform-origin: center;
        transform: translateY(-50%) rotate(45deg);
        position: absolute;
        top: 0;
        left: 50%; }
    .checkout_tabs .tab_body .qrcode_panel .body {
      width: 100%;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      border: 1px solid #ececec;
      padding-bottom: 10px; }
      .checkout_tabs .tab_body .qrcode_panel .body .tips {
        color: #333;
        font-size: 14px;
        margin-top: 5px;
        margin-bottom: 5px; }
      .checkout_tabs .tab_body .qrcode_panel .body .qr_code_wrap {
        width: 174px;
        margin: 0 auto 5px; }
        .checkout_tabs .tab_body .qrcode_panel .body .qr_code_wrap .qr_code {
          width: 174px;
          height: 174px; }
        .checkout_tabs .tab_body .qrcode_panel .body .qr_code_wrap .mask {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: rgba(255, 255, 255, 0.9); }
          .checkout_tabs .tab_body .qrcode_panel .body .qr_code_wrap .mask .mask_body {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%); }
            .checkout_tabs .tab_body .qrcode_panel .body .qr_code_wrap .mask .mask_body .text_overdue {
              font-size: 14px;
              color: #666; }
            .checkout_tabs .tab_body .qrcode_panel .body .qr_code_wrap .mask .mask_body .btn_refresh {
              width: 111px;
              height: 32px;
              background-color: orangered;
              border-radius: 2px;
              font-size: 16px;
              line-height: 32px;
              text-align: center;
              color: #fff;
              margin-top: 10px; }
      .checkout_tabs .tab_body .qrcode_panel .body .total {
        font-size: 14px;
        color: #999; }
        .checkout_tabs .tab_body .qrcode_panel .body .total .money {
          font-size: 18px;
          color: #ff4500; }
      .checkout_tabs .tab_body .qrcode_panel .body .status {
        font-size: 14px;
        color: #666; }
        .checkout_tabs .tab_body .qrcode_panel .body .status .countdown {
          color: #ff4500; }
  .checkout_tabs .tab_body .pay_method {
    width: 212px;
    height: 50px;
    background: white;
    border-radius: 2px;
    border: 1px solid #eeeeee;
    font-size: 16px;
    color: #333333;
    text-align: center; }
    .checkout_tabs .tab_body .pay_method .text {
      line-height: 50px; }
    .checkout_tabs .tab_body .pay_method + .pay_method {
      margin-left: 20px; }
    .checkout_tabs .tab_body .pay_method.selected .img_selected {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 24px;
      height: 24px; }
    .checkout_tabs .tab_body .pay_method .recommend {
      width: 40px;
      height: 21px;
      line-height: 21px;
      background-color: orangered;
      border-radius: 21px 21px 21px 1px;
      color: #fff;
      font-size: 12px;
      text-align: center;
      position: absolute;
      right: 0;
      top: -10px; }
    .checkout_tabs .tab_body .pay_method .qrcode_panel {
      position: absolute;
      left: 50%;
      top: 60px;
      transform: translateX(-50%); }
  .checkout_tabs .tab_body .pay_platform_container {
    height: 390px; }
  .checkout_tabs .tab_body .health_card_container {
    height: 200px; }
    .checkout_tabs .tab_body .health_card_container .bottom {
      margin-top: 100px; }
      .checkout_tabs .tab_body .health_card_container .bottom .btn_pay {
        width: 212px;
        height: 50px;
        background-color: orangered;
        border-radius: 4px;
        font-size: 22px;
        line-height: 50px;
        text-align: center;
        color: #fff; }
      .checkout_tabs .tab_body .health_card_container .bottom .total {
        margin-left: 20px;
        color: #666;
        line-height: 50px; }
        .checkout_tabs .tab_body .health_card_container .bottom .total .money {
          font-size: 16px;
          color: #ff4500;
          font-weight: bold; }

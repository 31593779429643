/* 商详页 */
.it_breadcrumbs.app-breadcrumbs .current { width: 300px; }
.it_breadcrumbs .customerService { vertical-align: 1px; color: #333; cursor: pointer; }

.it_base_wrap { margin-top: 40px; z-index: 101 !important; }
.it_pictures_curr, .it_pictures_bigimg { position: relative; z-index: 101; width: 400px; height: 400px; margin-bottom: 10px; display: block; border: 1px solid #E0E0E0; background-color: #fff; }
.it_pictures_curr .img { width: 100%; height: 100%; display: block; cursor: move; }
.it_pictures_bigimg { position: absolute; z-index: 101; top: -1px; left: 100%; margin-left: 10px; overflow: hidden; }
.it_pictures_bigimg .bigimg { width: 1600px; height: 1600px; display: block; z-index: 101; top: 0; left: 0; position: absolute; }
.it_pictures_magnifier { background-color: rgba(0,0,0,0.15); top: 0px; left: 0px; width: 100px; height: 100px; position: absolute; z-index: 102px; cursor: move; }

.it_pictures .preview { width: 60px; height: 60px; display: block; margin-left: 4px; }
.it_pictures .preview.curr { border: 2px solid #FF6F00; }

.it_pictures_prev_btn, .it_pictures_next_btn { width: 40px; overflow: hidden; height: 60px; position: relative; }
.it_pictures_prev_btn::before, .it_pictures_next_btn::before { display: block; content: ''; width: 10px; height: 10px; border-top: 1px solid #aaa; border-right: 1px solid #aaa; position: absolute; }
.it_pictures_prev_btn::before { top: 26px; left: 10px; transform: rotate(-135deg); }
.it_pictures_next_btn::before { top: 26px; right: 10px; transform: rotate(45deg); }

.it_pictures_con { width: 320px; height: 60px; }

.it_base { width: 750px; }
.it_title { color: #333; font-weight: bold; font-size: 18px; line-height: 26px; }
.it_prom_title { color: #FF7000; font-size: 14px; margin-top: 7px; line-height: 16px; }
.it_price_wrapper { margin-top: 14px; margin-bottom: 8px; background-color: #F8F8F8; padding: 6px 10px; font-size: 12px; color: #666; }
.it_price_item { vertical-align: baseline; margin-bottom: 8px; }
.it_price_wrapper .saleNum { margin-top: 6px; }
.it_price_item label { margin-right: 10px; color: #999; }
.it_price_item .priceSymbol { color: #FF4500; font-size: 14px; }
.it_price_item .soldOut { margin-right: 10px; color: #999; padding-top: 8px; float: left; }
.it_price_item .price { color: #FF4500; font-weight: bold; font-size: 22px; }
.it_price_item .sales li { padding-bottom: 6px; }
.it_price_item .saleTag { width: 48px; text-align: center; color: #FF6F00; border: 1px solid #FF6F00; padding: 2px 4px; border-radius: 2px; float: left; }
.it_price_item .saleCon { margin-top: 3px; margin-left: 6px; float: left; max-width: 500px; }
.it_price_item .saleCon .desc { margin-bottom: 6px; }
.it_price_item .sales .saleBtn { color: #666; font-size: 12px; margin-top: 3px; margin-left: 6px; vertical-align: 0px; cursor: pointer; }
.it_price_item .sales .saleBtn:after { content: ''; display: inline-block; width: 6px; height: 6px; border-top: 1px solid #666; border-right: 1px solid #666; transform: rotate(135deg); vertical-align: 3px; margin-left: 4px; }

.it_coupon_list { width: 387px; max-height: 280px; position: absolute; background: #fff; z-index: 10; top: 0px; left: 54px; box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.15); border-radius: 4px; border: 1px solid rgba(224,224,224,1); }
.it_coupon_list_title { position: relative; padding-top: 10px; padding-left: 15px; font-size: 14px; font-weight: bold; color: #333; }
.it_coupon_list_close { cursor: pointer; right: 20px; top: 10px; font-size: 12px; color: #666; }
.it_coupon_list_close::after { display: inline-block; content: ''; width: 6px; height: 6px; border-top: 1px solid #aaa; border-right: 1px solid #aaa; transform: rotate(-45deg); margin-left: 4px; }
.it_coupon_list_con { max-height: 240px; overflow: auto; }
.it_coupon_list ul { padding: 10px 15px; }
.it_coupon_list_item { margin: 0 auto 10px auto; height: 54px; color: #FF4500; border: 1px solid #FFD7B9; background-color: #FFF8F2; }
.it_coupon_list_item .price { text-align: center; width: 73px; word-break: break-all; line-height: 19px; height: 100%; position: relative; }
.it_coupon_list_item .price .center { padding-top: 10px; text-align: center; position: absolute; top: 50%; left: 0; transform: translate(0%, -50%); width: 100%; }
.it_coupon_list_item .price i { font-size: 14px; }
.it_coupon_list_item .price span { font-size: 20px; font-weight: bold; margin-top: 13px; }
.it_coupon_list_item .con { width: 180px; overflow: hidden; padding-top: 8px; }
.it_coupon_list_item .con .desc { width: 180px; font-weight: bold; font-size: 14px; }
.it_coupon_list_item .con .date { width: 180px; font-size: 12px; }
.it_coupon_list_item .btn { width: 60px; height: 25px; font-size: 12px; color: #fff; margin-top: 12px; margin-right: 12px; background-color: #FF7000; border-radius: 2px; cursor: pointer; }

.it_redpacket_list .it_coupon_list_item { border-radius: 4px; background-color: #FF6F00; border: 1px solid #FF6F00; color: #fff; }
.it_redpacket_list .it_coupon_list_item .price { color: #fff; }
.it_redpacket_list .it_coupon_list_item .btn { background-color: #fff; color: #FF6F00; }

.it_discount_list { width: 387px; max-height: 280px; position: absolute; background: #fff; z-index: 10; top: 0px; left: 54px; box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.15); border-radius: 4px; border: 1px solid rgba(224,224,224,1); }
.it_discount_list_title { @extend .it_coupon_list_title; }
.it_discount_list_close { @extend .it_coupon_list_close; }
.it_discount_item { margin: 8px 15px; border-bottom: 1px dashed #E0E0E0; padding-bottom: 10px; }
.it_discount_item .discountTitle { font-size: 14px; color: #FF4500; font-weight: bold; }
.it_discount_item .discountDesc { font-size: 12px; color: #666; }
.it_discount_item:last-child { border: 0; }

.it_base_label { color: #999; font-size: 12px; display: inline-block; margin-right: 12px; }
.it_delivery { margin-top: 15px; }
.it_delivery .hasStock { margin-left: 12px; display: inline-block; font-weight: bold; font-size: 12px; color: #666; }
.it_delivery .freight { margin-left: 30px; display: inline-block; color: #999; font-size: 12px; }

.it_specs .it_specs_item { cursor: pointer; display: inline-block; border: 1px solid #E0E0E0; height: 30px; border-radius: 2px; padding: 0 6px; line-height: 30px; font-size: 12px; color: #666; margin-right: 5px; }
.it_specs .it_specs_item.curr { border: 1px solid #FF4500; color: #FF4500; }
.it_specs .it_specs_item.disabled { border-color: #eee; color: #ddd; cursor: default; }

.it_buy_btns { margin-top: 15px; }
.it_buy_num { text-align: center; font-size: 14px; display: inline-block; vertical-align: -8px; }
.it_buy_num button, .it_buy_num input { vertical-align: bottom; }
.it_buy_button_reason { color: #FF6F00; font-size: 14px; }
.it_buy_btns .qtyMin { font-size: 12px; margin-left: 10px; color: #999; vertical-align: -1px; }
.it_buy_btn { cursor: pointer; margin-top: 15px; font-size: 18px; color: #fff; display: block; width: 200px; height: 50px; background: #FF6F00; border-radius: 2px; }

.it_special_spec { margin-top: 15px; background-color: #F8F8F8; color: #666; font-size: 12px; padding: 6px 10px; }
.it_services { margin-top: 15px; font-size: 14px; color: #FF6F00; }

%it_title { font-size: 18px; color: #666; text-align: center; margin-bottom: 10px; line-height: 20px; }

.it_announcements { background-color: #FAFAFA; border: 1px solid #EEEEEE; padding: 20px; margin-top: 20px; }
.it_announcements .hd { @extend %it_title; }

.it_drug_process { @extend .it_announcements; margin-top: 15px; text-align: center; }
.it_drug_process .hd { @extend %it_title; }

.it_detail_head { margin-top: 15px; height: 50px; background-color: #FAFAFA; border: 1px solid #EEEEEE; text-align: center; position: relative; }
.it_detail_head .tit { font-weight: bold; font-size: 18px; height: 20px; line-height: 20px; position: absolute; width: 100%; top: 50%; margin-top: -10px; }
.it_detail_head .btns { float: right; position: relative; z-index: 10; }
.it_detail_head .btns button { margin-right: 12px; width: 102px; height: 32px; border-radius: 2px; font-size: 14px; margin-top: 8px; cursor: pointer; }
.it_detail_head .btns .btn_cart { background-color: #FF6F00; color: #fff; }
.it_detail_head .btns .btn_service { color: #FF6F00; border: 1px solid #FF6F00; background-color: #fff; }

.it_detail_head.fixed { width: 1180px; position: fixed; z-index: 1000; top: 0px; margin-top: 0px; }

.it_details_main { border: 1px solid #EEEEEE; border-top: 0; padding-top: 1px; padding-bottom: 40px; }
.it_details_hd { font-size: 16px; margin-bottom: 10px; font-weight: bold; color: #666; }
.it_details_con { margin: 30px 50px 0 50px; }
.it_normal_specs_con { padding: 20px 0; background-color: #FAFAFA; border: 1px solid #EEEEEE; padding-right: 40px; }
.it_normal_specs_con table td { padding-left: 40px; padding-bottom: 5px; width: 50%; }

.it_drug_permit { padding-left: 40px; margin-top: 15px; font-weight: bold; color: #666; }
.it_drug_permit_link { margin-left: 20px; color: #4F4F4F; text-decoration: underline; }

.it_instructions_table,.it_instructions_table th,.it_instructions_table td { border-collapse: collapse; border: 1px solid #eee; }
.it_instructions_table th { background-color: #FAFAFA; padding: 10px 10px 10px 20px; width: 150px; vertical-align: top; text-align: left; }
.it_instructions_table td { padding: 10px; }

.it_details_rich_text { padding-top: 20px; }
.it_details_rich_text img { display: block; width: 100%; }

.it_price_description { padding-top: 20px; }
.it_price_description img { display: block; width: 100%; }
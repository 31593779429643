.tabs_coupon_help_container {
    margin-top: 10px;
}
.my_coupon_tabs {
    display: inline-block;
    border: 1px solid #E0E0E0;
    border-radius: 2px;
    overflow: hidden;

    li {
        display: inline-block;
        padding: 9px 32px;
        border-right: 1px solid #E0E0E0;
        background-color: white;
        color: #333;
        font-size: 14px;
        cursor: pointer;
    }

    li:last-child {
        border-right: none;
    }

    .selected {
        background-color: #FF6F00;
        color: white;
    }
}

.coupon_help {
    float: right;
    margin-top: 8px;
    margin-right: 10px;
    cursor: pointer;
    // display: inline-block;
    // margin-left: 100px;
    .icon {
        width: 14px;
        height: 14px;
        border-radius: 7px;
        background-color: #FF6F00;
        display: inline-block;
        text-align: center;
        font-size: 12px;
        color: white;
        vertical-align: middle;
    }

    .help {
        margin-left: 5px;
        color: #666;
        font-size: 12px;
    }
}

.my_coupon_header {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid darkgray;
    // box-sizing: border-box;

    button {
        float: right;
        background-color: white;
        color: #FF6F00;
        padding: 3px 5px;
        border: 1px solid #FF6F00;
        border-radius: 2px;
        font-size: 14px;
    }
}

.couponlist {
    min-height: 640px;
    .empty {
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .img {
            width: 170px;
            height: 170px;
            background: url('../images/empty.png') no-repeat;
            background-size: 100%;
        }
    }

    .spin {
        padding-top: 20px;
        display: flex;
        justify-content: center;

        .loading {
            width: 60px;
            height: 60px;
            background: url('../images/loding.gif') no-repeat;
            background-size: 100%;
            display: block;
        }
    }
}

.coupon_container {
    margin-top: 10px;
    margin-right: 9px;
    display: inline-block;
    overflow: hidden;

    .left_arc_line {
        width: 14px;
        height: 14px;
        border: 1px solid #DDD;
        position: absolute;
        border-radius: 7px;
        left: -7px;
        top: 108px;
        background: white;
    }
    .right_arc_line {
        width: 14px;
        height: 14px;
        border: 1px solid #DDD;
        position: absolute;
        border-radius: 7px;
        left: 198px;
        top: 108px;
        background: white;
    }
    .detailModal {
        color: #666;
        font-size: 14px;
        bottom: 129px;
        width: 206px;
        padding: 24px;
        background:rgba(255,255,255,1);
        box-shadow:0px 4px 20px 0px rgba(0,0,0,0.15);
        border:1px solid rgba(224,224,224,1);
        .triangle {
            left: 166px;
            bottom: -4px;
            width: 10px;
            height: 10px;
            background: white;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}

.used_and_expired_coupon_container {
    width: 206px;
    border: 1px solid #DDD;
    position: relative;
    overflow: hidden;

    .top {
        background-color: #F8F8F8;
        text-align: center;
        padding: 27px 0;
        color: #666;

        .price {
            font-size: 20px;
            font-weight:500;
            .priceNo {
                font-size: 40px;
            }
        }

        .threshold {
            margin-top: 8px;
            font-size: 14px;
            .ruleTag {
                padding: 0 5px;
                font-size: 12px;
                border: 1px solid orangered;
                margin-right: 3px;
            }
        }
    }

    .dash_line {
        margin: 0 15px;
        height: 1px;
        border-bottom: 1px dashed #DDD;
    }

    .tag_title {
        margin: 15px 15px 1px;
        height: 45px;

        .tag {
            display: inline-block;
            width: 48px;
            height: 20px;
            text-align: center;
            line-height: 20px;
            background-color: #999;
            border-radius: 12px;
            font-size: 12px;
            color: white;
        }
    
        .title {
            margin-left: 5px;
            color: #666;
            font-size: 16px;
        }
    }

    .valid_date {
        margin: 14px 15px 0;
        height: 34px;
        font-size: 12px;
        color: #666;
        word-break: break-all;
    }

    .use_instruction {
        margin: 5px 15px 0;
        font-size: 12px;
        color: #666;
        cursor: pointer;

        .detail {
            width: 89px;
            vertical-align: text-bottom;
        }

        .help {
            width: 14px;
            height: 14px;
            // line-height: 16px;
            border-radius: 8px;
            color: #666;
            background-color:white;
            border: 1px solid #666;
            font-size: 10px;
            display: inline-block;
            text-align: center;
            // vertical-align: top;
        }
    }

    .used_or_expired_img {
        display:inline-block;
        margin: 20px 130px 20px 0;
        margin-left: 130px;
        height: 60px;;
        width: 60px;
    }
    .activityBtn {
        margin:20px 15px ;
        padding:6px 55px;
        color: white;
        background-color: #FF6F00;
        font-size: 14px;
    }
}
.used_and_expired_coupon_container.notused {
    border:1px solid rgba(255,204,197,1);
    .top {
        color: #FF4500;
        background-color: #ffccc547;
    }
    .dash_line {
        border-bottom: 1px dashed #FFCCC5;
    }
    .tag {
        background-color: #FF6F00;
    }
    .tag.redpack {
        background-color: #FF4500;
    }
    .use_instruction {
        .help {
            border: 1px solid #FF6F00;
            color: #FF6F00;
        }
    }
}

.coupon_container.notused {
    .left_arc_line {
        border: 1px solid #ffccc5;
    }
    .right_arc_line {
        border: 1px solid #ffccc5;
    }
    .new_img {
        top: 0;
        left: 0;
        width: 50px;
    }
    .detailModal {
        bottom: 97px;
    }
}

.add-coupon-modal {
    width: 380px;
    .add-coupon-modal-container {
    width: 380px;
    padding: 20px 30px;
        .add-coupon-modal-hd {
            font-size: 20px;
            color: #666;
            margin-bottom: 40px;
        }
        .add-coupon-modal-input {
            width: 320px;
            height: 44px;
            border: 1px solid #FF6F00;
            border-radius: 2px;
            padding: 0 8px;
            color: #000;
            outline: none;
            font-size: 16px;
        }
        .add-coupon-modal-label {
            font-size: 14px;
            color: #666;
            margin-bottom: 12px;
        }
        .add-coupon-modal-btn {
            width:320px;
            padding: 10px;
            margin: 40px 0 20px;
            font-size: 16px;
            background:rgba(255,111,0,1);
            border-radius:4px;
            color: white;
        }
        .errmsg {
            bottom: 98px;
            color: #FF4500;
            font-size: 14px;
            .icon-warn {
                font-size: 14px;
                margin-right: 3px;
            }
        }
        .close {
            right: 30px;
            top: 17px;
            color: #999;
            font-size: 20px;
            font-weight: bold;
        }
    }
}

.checkout {
  background-color: #fff;
  padding-bottom: 40px;

  .cs_p {
    cursor: pointer;
  }

  .body {
    width: 1180px;
    margin: 0 auto;
  }
}

.checkout_info {
  padding: 30px;
  background-color: rgba(255, 249, 231, 1);
  border: 1px solid rgba(236, 236, 236, 1);

  .img_status {
    width: 38px;
    height: 38px;
  }

  .status {
    width: 413px;
    height: 31px;
    font-size: 24px;
    color: #999;
  }

  .money {
    color: #ff4500;
    font-size: 20px;
  }
}

.checkout_tabs {
  .tab_header {
    .tab {
      width: 120px;
      height: 41px;
      background-color: rgba(239, 239, 239, 1);
      border-radius: 2px 2px 0px 0px;
      border: 1px solid rgba(204, 204, 204, 1);
      font-size: 14px;
      line-height: 41px;
      text-align: center;
      color: #666;

      &.selected {
        background-color: rgba(255, 255, 255, 1);
        border-bottom: none;
      }

      & + .tab {
        margin-left: 5px;
      }
    }
  }

  .tab_body {
    padding: 30px 20px;
    background-color: #fff;
    border: solid 1px rgba(204, 204, 204, 1);
    margin-top: -1px;

    .qrcode_panel {
      display: inline-block;
      width: 212px;
      text-align: center;
      border-radius: 4px;

      .header {
        height: 55px;
        line-height: 55px;
        color: #fff;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;

        .triangle {
          width: 10px;
          height: 10px;
          transform-origin: center;
          transform: translateY(-50%) rotate(45deg);
          position: absolute;
          top: 0;
          left: 50%;
        }
      }

      .body {
        width: 100%;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border: 1px solid rgba(236, 236, 236, 1);
        padding-bottom: 10px;

        .tips {
          color: #333;
          font-size: 14px;
          margin-top: 5px;
          margin-bottom: 5px;
        }

        .qr_code_wrap {
          width: 174px;
          margin: 0 auto 5px;

          .qr_code {
            width: 174px;
            height: 174px;
          }

          .mask {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(256, 256, 256, 0.9);

            .mask_body {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);

              .text_overdue {
                font-size: 14px;
                color: #666;
              }

              .btn_refresh {
                width: 111px;
                height: 32px;
                background-color: rgba(255, 69, 0, 1);
                border-radius: 2px;
                font-size: 16px;
                line-height: 32px;
                text-align: center;
                color: #fff;
                margin-top: 10px;
              }
            }
          }
        }

        .total {
          font-size: 14px;
          color: #999;

          .money {
            font-size: 18px;
            color: #ff4500;
          }
        }

        .status {
          font-size: 14px;
          color: #666;

          .countdown {
            color: #ff4500;
          }
        }
      }
    }

    .pay_method {
      width: 212px;
      height: 50px;
      background: rgba(255, 255, 255, 1);
      border-radius: 2px;
      border: 1px solid rgba(238, 238, 238, 1);
      font-size: 16px;
      color: rgba(51, 51, 51, 1);
      text-align: center;

      .text {
        line-height: 50px;
      }

      & + .pay_method {
        margin-left: 20px;
      }

      &.selected {
        .img_selected {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 24px;
          height: 24px;
        }
      }

      .recommend {
        width: 40px;
        height: 21px;
        line-height: 21px;
        background-color: rgba(255, 69, 0, 1);
        border-radius: 21px 21px 21px 1px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        position: absolute;
        right: 0;
        top: -10px;
      }

      .qrcode_panel {
        position: absolute;
        left: 50%;
        top: 60px;
        transform: translateX(-50%);
      }
    }

    .pay_platform_container {
      height: 390px;
    }

    .health_card_container {
      height: 200px;

      .bottom {
        margin-top: 100px;

        .btn_pay {
          width: 212px;
          height: 50px;
          background-color: rgba(255, 69, 0, 1);
          border-radius: 4px;
          font-size: 22px;
          line-height: 50px;
          text-align: center;
          color: #fff;
        }

        .total {
          margin-left: 20px;
          color: #666;
          line-height: 50px;

          .money {
            font-size: 16px;
            color: #ff4500;
            font-weight: bold;
          }
        }
      }
    }
  }
}

$PERSON_INFO_FONT_SIZE: 14px;
$PHYSICALITY_ITEM_WIDTH: 180px;
$PHYSICALITY_ITEM_HEIGHT: 52px;
.person_info {
    background-color: white;
    height: 100vh;
    padding-top: 0px;

    td {
        padding:10px 0;
    }
    img {
        width: 50px;
        height: 50px;
        display: block;
        border-radius: 30px;
    }

    .hint {
        font-size: $PERSON_INFO_FONT_SIZE;
        color: #666;
    }

    .star {
        color: #FF4500;
    }

    .value {
        font-size: $PERSON_INFO_FONT_SIZE;
        color: #333;
    }

    .left_margin {
        margin-left: 10px;
    }

    .height_error {
        position: absolute;
        left: 10px;
        top: 0px;
    }

    .weight_error {
        position: absolute;
        left: $PHYSICALITY_ITEM_WIDTH + 20px;
        top: 0px;
    }

    .age_error {
        position: absolute;
        left: $PHYSICALITY_ITEM_WIDTH * 2+ 30px;
        top: 0px;
    }

    .female_left_margin {
        margin-left: 30px;
    }

    .save_btn {
        width:100px;
        height:32px;
        background:#FF6F00;
        border-radius:2px;
        color:white;
        font-size: 14px;
    }

    .unable_btn {
        background-color: #999999;
    }
    .name {
        width: 200px;
        height: 32px;
        display: inline-block;
        font-size: $PERSON_INFO_FONT_SIZE;
        border: 1px solid #ECECEC;
        padding-left: 10px;
        padding-right: 10px;
    }
    .name_prefix_width {
        width: 10px;
        display:inline-block;
    }
    .physicality_container {
        width: $PHYSICALITY_ITEM_WIDTH;
        height: $PHYSICALITY_ITEM_HEIGHT;
        background-color: #F8F8F8;
        display: inline-block;
        border-radius: 4px;
        margin-right: 10px;

        span {
            font-size: $PERSON_INFO_FONT_SIZE;
            color: #666;
            margin-left: 16px;
            display: inline;
            line-height: $PHYSICALITY_ITEM_HEIGHT;
        }

        input {
            margin-left: 5px;
            width: 72px;
            height: 32px;
            border-radius: 2px;
            display: inline-block;
            font-size: $PERSON_INFO_FONT_SIZE;
            background-color: white;
            border-radius: 2px;
            border: none;
            padding-left: 10px;
            padding-right: 10px;
        }
        span:last-child {
            margin-left: 8px;
        }
    }

    // .physicality_container:after {
    //     display: inline-block;
    //     vertical-align: middle;
    //     content: '';
    //     height: 100%;
    // }

    .error_container {
        display: inline-block;
        .error_icon {
            width: 14px;
            height: 14px;
            background-color: #FF4500;
            color: white;
            display: inline-block;
            line-height: 14px;
            text-align: center;
            border-radius: 7px;
            font-size: 7px;
            vertical-align: middle;
        }
        .error_msg {
            margin-left: 6px;
            color: #FF4500;
            font-size: 14px;
            vertical-align: middle;
        }
    }

    .nickname_left_margin {
        margin-left: 12px;
    }

    .error_block {
        width: $PHYSICALITY_ITEM_WIDTH;
    }

    .hide_error {
        display: none;
    }

    .relative_position {
        position: relative;
    }
}


@import './variables.scss';

.mall_common__header {
}
.mall_common__header__nav {
    height: $hd_nav_height;
    background-color: #f0f0f0;
}
.mall_common__header__nav_container {
    width: $web_width;
    height: 100%;
    margin-left: auto;
    margin-right: auto;

    font-size: 14px;
    color: #666;
    line-height: $hd_nav_height;
}

.mall_common__header__login_info {
    display: inline-block;
}
.mall_common__header__welcome {
    display: inline-block;
    color: #999;

    &:after {
        content: "";
        display: none;
        width: 2px;
        height: 1em;
        margin-left: 5px;
        margin-right: 10px;
        vertical-align: -2px;

        background-color: #999;
    }
}
.mall_common__header__login {
    display: inline-block;
    margin-left: 15px;
    margin-right: 20px;
    cursor: pointer;
}
.mall_common__header__register {
    display: inline-block;
    margin-right: 20px;
    cursor: pointer;
}
.mall_common__header__user {
    display: inline-block;
    position: relative;
    cursor: pointer;

    &:hover {
        background-color: #fff;
        color: #ff6f00;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);

        .mall_common__header__user_dropdown_list {
            display: block;
        }

        .mall_common__header__user_nick_name_arrow_icon {
            background-image: url("./images/arrow_up.png");     
        }
    }
}
.mall_common__header__user_nick_name {
    padding-left: 10px;
    padding-right: 10px;
}
.mall_common__header__user_nick_name_arrow_icon {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-left: 4px;

    vertical-align: 0px;
    background: center / contain no-repeat url("./images/arrow_down.png");     
}
.mall_common__header__user_info_icon,
.mall_common__header__user_logout_icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 6px;

    vertical-align: 0px;
    background-repeat: no-repeat;
    background-size: contain;  
}
.mall_common__header__user_info_icon {
    vertical-align: -3px;
    background-image: url("./images/user.png");
}
.mall_common__header__user_logout_icon {
    vertical-align: -3px;
    background-image: url("./images/logout.png");
}
.mall_common__header__user_dropdown_list {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: $max_zindex;

    padding: 5px 20px 5px 10px;
    margin: 0;
    list-style: none;
    white-space: nowrap;
    background-color: #fff;
    border-top: none;
    color: #666;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1);

    & > li {
        line-height: 2em;
    }
}


.mall_common__header__quick_menu {
    float: right;
}
.mall_common__header__my_pajk {
    display: inline-block;
}
.mall_common__header__my_pajk_shop {
    display: inline-block;
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 20px;
    cursor: pointer;

    &:hover {
        border-left: 1px solid #eee;
        border-right: 1px solid #eee;
        background-color: #fff;
        color: #ff6f00;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);

        .mall_common__header__my_pajk_dropdown_list {
            display: block;
        }

        .mall_common__header__my_pajk_shop_arrow_icon {
            background-image: url("./images/arrow_up.png");   
        }
    }
}
.mall_common__header__my_pajk_shop_arrow_icon {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-left: 4px;

    vertical-align: 0px;
    background: center / contain no-repeat url("./images/arrow_down.png");   
}
.mall_common__header__my_pajk_shop_coupon_icon,
.mall_common__header__my_pajk_shop_order_icon,
.mall_common__header__my_pajk_shop_address_icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 6px;

    vertical-align: -2px;
    background-repeat: no-repeat;
    background-size: contain;    
}
.mall_common__header__my_pajk_shop_coupon_icon {
    background-image: url("./images/coupon.png");
}
.mall_common__header__my_pajk_shop_order_icon {
    background-image: url("./images/order.png");
}
.mall_common__header__my_pajk_shop_address_icon {
    background-image: url("./images/address.png");
}
.mall_common__header__my_pajk_dropdown_list {
    display: none;
    position: absolute;
    top: 100%;
    left: -1px;
    z-index: $max_zindex;

    padding: 0;
    margin: 0;
    list-style: none;
    padding: 5px 10px 5px 10px;
    margin: 0;
    list-style: none;
    white-space: nowrap;
    background-color: #fff;
    border: 1px solid #eee;
    border-top: none;
    color: #666;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1);

    & > li {
        line-height: 2em;
    }
}
.mall_common__header__mobile_app {
    position: relative;
    display: inline-block;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    color: #666;
    cursor: pointer;

    &:hover {
        background-color: #fff;
        color: #ff6f00;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);

        .mall_common__header__mobile_app_dropdown_list {
            display: block;
        }

        .mall_common__header__mobile_app_arrow_icon {
            background-image: url("./images/arrow_up.png");
        }

        .mall_common__header__mobile_app_phone_icon {
            background-image: url("./images/phone_actived.png");
        }
    }
}
.mall_common__header__mobile_app_title {
    margin: 0;
    font-size: 14px;
    font-weight: bold;
}
.mall_common__header__mobile_app_desc {
    margin: 0;
    font-size: 12px;
}
.mall_common__header__mobile_app_dropdown_list {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    z-index: $max_zindex;

    width: 184px;
    padding: 0;
    margin: 0;
    padding: 5px 20px 0px 20px;
    box-sizing: content-box;
    list-style: none;
    background-color: #fff;
    border-top: 0;
    color: #666;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1);
    cursor: default;
    line-height: initial;

    & > li {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}
.mall_common__header__mobile_app_qrcode {
    display: inline-block;
    width: 72px;
    height: 72px;
    padding: 1.5px;
    box-sizing: border-box;
    margin-right: 10px;

    vertical-align: middle;

    & img {
        display: block;
        width: 100%;
        height: 100%;
    }
}
.mall_common__header__mobile_app_info {
    display: inline-block;
    width: 100px;
    vertical-align: middle;
    line-height: 1.4em;
}
.mall_common__header__mobile_app_phone_icon {
    display: inline-block;
    width: 11px;
    height: 16px;
    margin-right: 6px;

    vertical-align: -2px;
    background: center / contain no-repeat url("./images/phone_default.png");
}
.mall_common__header__mobile_app_arrow_icon {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-left: 4px;

    vertical-align: 0px;
    background: center / contain no-repeat url("./images/arrow_down.png");
}
.mall_common__header__mobile_app_horizontal {
    cursor: default;
    padding-right: 0;
    z-index: 1;

    vertical-align: top;
    background-color: transparent !important;

    &:hover {
        box-shadow: none;
    }

    .mall_common__header__mobile_app_dropdown_list {
        position: static;
        display: inline-block;
        box-shadow: none;
        padding: 0;

        background-color: transparent;
        text-align: right;
        white-space: nowrap;

        & > li {
            display: inline-block;
            padding-left: 5px;
            padding-right: 5px;
            margin:0;
            text-align: center;
        }
    }
    .mall_common__header__mobile_app_qrcode {
        display: block;
        width: 66px;
        height: 66px;
        margin-left: auto;
        margin-right: auto;
    }

    .mall_common__header__mobile_app_info {
        width: auto;
    }

    .mall_common__header__mobile_app_title {
        text-align: center;
        font-weight: normal;
        font-size: 12px;
    }

    .mall_common__header__mobile_app_desc {
        display: none;
    }
}


.mall_common__header__extra {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #fff;
}
.mall_common__header__extra_container {
    width: $web_width;
    margin-left: auto;
    margin-right: auto;
}
.mall_common__header__slogan {
    display: inline-block;
    width: 222px;
    margin-right: 70px;
    cursor: pointer;
    vertical-align: top;

    img {
        display: block;
        width: 100%;
    }
}

.mall_common__header__logo {
    display: inline-block;
    cursor: pointer;
    vertical-align: top;

    img {
        vertical-align: -10px;
        width: 220px;
        height: 40px;
        display: inline-block;
    }
    span {
        font-weight: 450;
    }
}

.mall_common__header__search {
    display: inline-block;
}
.mall_common__header__search_bar {
    display: inline-block;
    height: 40px;
}
.mall_common__header__search_input {
    position: relative;
    display: inline-block;
    width: 400px;
    height: 100%;
    border: 2px solid #ff6f00;
    box-sizing: border-box;

    vertical-align: top;

    & input {
        outline: none;
        border: none;
        display: block;
        width: 100%;
        height: 100%;
        padding-left: 40px;
        box-sizing: border-box;
        
        font-size: 16px;
        color: #333;

        &::placeholder {
            color: #aaa;
        }
    }
}
.mall_common__header__search_input_thinking_list {
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    z-index: $max_zindex;

    width: 100%;
    margin-top: 2px;
    max-height: 250px;
    overflow-y: auto;

    background: #fff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);

    & > li {
        padding: 10px 0px;
        margin: 5px 20px;
        border-bottom: 1px solid #f5f5f5;

        cursor: pointer;

        strong {
            color: #aaa;
            font-weight: normal;
        }
    }
}
.mall_common__header__search_input_icon {
    position: absolute;
    left: 10px;
    top: 8px;
    width: 20px;
    height: 20px;

    background: center / contain no-repeat url("./images/search.png");
}
.mall_common__header__search_btn {
    display: inline-block;
    width: 120px;
    height: 100%;

    font-size: 16px;
    text-align: center;
    line-height: 40px;
    background-color: #ff6f00;
    color: #fff;
    cursor: pointer;
    vertical-align: top;
}
.mall_common__header__search_hot_tags {
    font-size: 14px;
    color: #aaa;
    margin-top: 5px;
    cursor: default;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    span {
        display: inline-block;
        margin-left: 10px;

        color: #666;
        cursor: pointer;
    }
}

.mall_common__header__cart {
    position: relative;
    display: inline-block;
    width: 130px;
    height: 40px;
    margin-left: 30px;
    border: 1px solid #e0e0e0;
    text-align: center;
    line-height: 40px;
    vertical-align: top;
    font-size: 16px;
    cursor: pointer;
}
.mall_common__header__cart_icon {
    display: inline-block;
    width: 22px;
    height: 22px;
    margin-right: 6px;

    background-image: url("./images/cart.png");
    background-repeat: no-repeat;
    background-size: contain;
    vertical-align: -5px;
}
.mall_common__header__cart_count {
    position: absolute;
    right: -3px;
    top: 0;

    width: 27px;
    height: 18px;
    margin-top: -13px;
    border-radius: 100px;

    font-size: 12px;
    color: #fff;
    text-align: center;
    line-height: 18px;
    background-color: #ff4500;
}
.mall_common__header__custom {
    display: inline-block;
}
.as__after_sale {
    padding-top: 20px;
    padding-bottom: 56px;
}

.as__after_sale_main {
    padding: 40px 60px;
}

.as__top_steps_container {
    margin-bottom: -10px;
    text-align: right;

    .as__top_steps {
        display: inline-block;

        .inner {
            width: 30px !important;
            height: 30px !important;
            
            i {
                font-size: 16px !important;
                line-height: 30px !important;
            }
        }

        .main {
            width: 114px;
            text-align: center;
            font-size: 14px;
        }
    }
}

.as__order_info {
    font-size: 16px;
    color: #333;

    & > .title {
        margin-top: 30px;
        margin-bottom: 20px;
    }

    & > .main {
        padding-top: 10px;
        padding-bottom: 20px;
        border: 1px solid #e0e0e0;
        border-radius: 2px;

        & > .row {
            margin: 10px 20px;
    
            .label,
            .cont {
                display: inline-block;
                vertical-align: top;
            }

            .label {
                margin-right: 10px;
            }
        }
    }

    .as__refund_item {
        .title {
            font-size: 14px;
        }
    }
}

.as__refund_record {
    font-size: 16px;
    color: #333;

    & > .title {
        margin-top: 30px;
        margin-bottom: 20px;

        .btn_cancel {
            float: right;
            padding: 3px 7px;
            border: 1px solid #999;
            border-radius: 4px;
            font-size: 14px;
            color: #666;
            line-height: 24px;
            cursor: pointer;
        }
    }

    & > .main {
        padding-top: 10px;
        padding-bottom: 20px;
        border: 1px solid #e0e0e0;
        border-radius: 2px;
    }
}

.as__refund_steps {
    margin: 10px 10px;

    .head {
        &:before {
            display: none;
        }

        .inner {
            i {
                width: 6px !important;
                height: 6px !important;
                margin-left: auto;
                margin-right: auto;
                background: #e0e0e0 !important;
                margin-top: 10px;
            }
        }
    }

    .main {
        padding-left: 0 !important;
    }

    .desc {
        font-size: 16px;
        color: #333;
    }

    .additionalDesc {
        margin-top: 5px;
        line-height: 1.6em;
    }

    .date {
        margin-top: 5px;
        color: #bdbdbd;
        line-height: 22px;
    }
}

.as__refund_express_form {
    margin-top: 20px;

    .app-form-input::placeholder {
        color: #bdbdbd;
    }

    .as__refund_item {
        .title {
            font-size: 14px;
        }
    }

    .address .app-form-input-wrap {
        vertical-align: top;
    }
}

.as__after_sale_success {
    padding: 30px 30px 46px 30px;
    margin-top: 40px;
    background-color: #fff9e7;

    .icon {
        display: inline-block;
        width: 32px;
        height: 32px;
        margin-right: 36px;
        vertical-align: top;
        background-image: url("../images/error.png");
        background-size: contain;
        background-repeat: no-repeat;
    }

    .info {
        display: inline-block;
        vertical-align: top;

        .status {
            font-size: 24px;
            color: #333;
        }

        .desc {
            margin-top: 10px;
            font-size: 14px;
            color: #666;
            line-height: 24px;
        }
    }
}

.as__top_tip {
    padding: 30px 30px 46px 30px;
    margin-top: 40px;
    background-color: #fff9e7;

    .icon {
        display: inline-block;
        width: 32px;
        height: 32px;
        margin-right: 20px;
        vertical-align: -9px;
        background-image: url("../images/error.png");
        background-size: contain;
        background-repeat: no-repeat;
    }  
    
    .btn_check {
        padding: 3px 7px;
        margin-left: 30px;
        border: 1px solid #999;
        border-radius: 4px;
        font-size: 14px;
        color: #666;
        line-height: 24px;
        cursor: pointer;        
    }
}
.icons-backtop:before,.icons-consult:before,.icons-notice:before,.icons-warn:before,.icons-invalid:before,.icons-cart:before,.icons-backtop-rem:before,.icons-consult-rem:before,.icons-notice-rem:before,.icons-warn-rem:before,.icons-invalid-rem:before,.icons-cart-rem:before{background-image: url("sprite-icons.png");content:'';display:inline-block;font-size:0px;vertical-align:middle;background-size: 64px 216px;}
.icons-backtop-rem:before,.icons-consult-rem:before,.icons-notice-rem:before,.icons-warn-rem:before,.icons-invalid-rem:before,.icons-cart-rem:before{background-size: 0.32rem 1.08rem;}
.icons-backtop:before{background-position: -0px -0px; width: 32px; height: 32px;}
.icons-backtop-rem:before{background-position: -0rem -0rem; width: 0.16rem; height: 0.16rem;}
.icons-consult:before{background-position: -0px -32px; width: 32px; height: 32px;}
.icons-consult-rem:before{background-position: -0rem -0.16rem; width: 0.16rem; height: 0.16rem;}
.icons-notice:before{background-position: -0px -64px; width: 64px; height: 64px;}
.icons-notice-rem:before{background-position: -0rem -0.32rem; width: 0.32rem; height: 0.32rem;}
.icons-warn:before{background-position: -0px -128px; width: 36px; height: 36px;}
.icons-warn-rem:before{background-position: -0rem -0.64rem; width: 0.18rem; height: 0.18rem;}
.icons-invalid:before{background-position: -0px -164px; width: 20px; height: 20px;}
.icons-invalid-rem:before{background-position: -0rem -0.82rem; width: 0.1rem; height: 0.1rem;}
.icons-cart:before{background-position: -0px -184px; width: 32px; height: 32px;}
.icons-cart-rem:before{background-position: -0rem -0.92rem; width: 0.16rem; height: 0.16rem;}
.item-invalid-coupon:before,.item-invalid-redpacket:before,.item-received-coupon:before,.item-received-redpacket:before,.item-sack-coupon:before,.item-sack-redpacket:before,.item-invalid-coupon-rem:before,.item-invalid-redpacket-rem:before,.item-received-coupon-rem:before,.item-received-redpacket-rem:before,.item-sack-coupon-rem:before,.item-sack-redpacket-rem:before{background-image: url("sprite-item.png");content:'';display:inline-block;font-size:0px;vertical-align:middle;background-size: 48px 288px;}
.item-invalid-coupon-rem:before,.item-invalid-redpacket-rem:before,.item-received-coupon-rem:before,.item-received-redpacket-rem:before,.item-sack-coupon-rem:before,.item-sack-redpacket-rem:before{background-size: 0.24rem 1.44rem;}
.item-invalid-coupon:before{background-position: -0px -0px; width: 48px; height: 48px;}
.item-invalid-coupon-rem:before{background-position: -0rem -0rem; width: 0.24rem; height: 0.24rem;}
.item-invalid-redpacket:before{background-position: -0px -48px; width: 48px; height: 48px;}
.item-invalid-redpacket-rem:before{background-position: -0rem -0.24rem; width: 0.24rem; height: 0.24rem;}
.item-received-coupon:before{background-position: -0px -96px; width: 48px; height: 48px;}
.item-received-coupon-rem:before{background-position: -0rem -0.48rem; width: 0.24rem; height: 0.24rem;}
.item-received-redpacket:before{background-position: -0px -144px; width: 48px; height: 48px;}
.item-received-redpacket-rem:before{background-position: -0rem -0.72rem; width: 0.24rem; height: 0.24rem;}
.item-sack-coupon:before{background-position: -0px -192px; width: 48px; height: 48px;}
.item-sack-coupon-rem:before{background-position: -0rem -0.96rem; width: 0.24rem; height: 0.24rem;}
.item-sack-redpacket:before{background-position: -0px -240px; width: 48px; height: 48px;}
.item-sack-redpacket-rem:before{background-position: -0rem -1.2rem; width: 0.24rem; height: 0.24rem;}

.cm_slider {
    width: 100%;
    height: 500px;
    list-style: none;
    overflow: hidden;
    position: relative;

    li {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 1s ease-in-out;
        pointer-events: none;

        img {
            width: 100%;
            height: 100%;
        }

        &.visible {
            opacity: 1;
            pointer-events: initial !important;
        }
    }
}

.oi_orderinfo_wrap { padding: 20px; }
.oi_toptips { margin-bottom: 15px; background-color: #FFF9E7; padding: 10px; font-size: 14px; color: #FA6400; border: 1px solid #ECECEC; }
.oi_status { margin-bottom: 15px; background-color: #FFF9E7; padding: 32px; font-size: 14px; color: #333; border: 1px solid #ECECEC; }
.oi_status_waring, .oi_status_success { vertical-align: middle; width: 32px; height: 32px; border-radius: 50%; display: inline-block; margin-right: 22px; background-color: #FF4500; }
.oi_status_waring:before, .oi_status_waring::after { content: ''; width: 2px; height: 12px; display: block; background-color: #fff; margin-left: 50%; margin-top: 9px; position: relative; left: -1px; }
.oi_status_waring::after { height: 2px; margin-top: 2px; }
.oi_status_text .text { font-size: 24px; color: #333; vertical-align: middle; }

.oi_status_success { background-color: #88C905; position: relative; }
.oi_status_success:before { position: relative; display: block; content: ""; width: 15px; height: 8px; border-left: 2px solid #fff; border-bottom: 2px solid #fff; transform: rotate(-45deg); left: 8px; top: 8px; }

.oi_buttons { padding-top: 15px; padding-left: 54px; }
.oi_buttons button { cursor: pointer; height: 30px; border: 1px solid #ddd; border-radius: 4px; padding-left: 12px; padding-right: 12px; margin-right: 30px; color: #666; font-size: 14px; }
.oi_buttons button.btn_solid { background-color: #FA6400; color: #fff; border: 0px; }

.oi_title { font-size: 16px; color: #333; margin-bottom: 20px; padding-top: 5px; }
.oi_basic_info { color: #333; border: 1px solid #E0E0E0; padding: 10px 20px; border-radius: 2px; font-size: 16px; margin-bottom: 15px; }
.oi_basic_info li { line-height: 30px; }
.oi_basic_info li span { vertical-align: middle; }
.oi_basic_info .input { border: 0px; font-size: 16px; color: #333; vertical-align: middle; background-color: #fff; }

.oi_items { border: 1px solid #ECECEC; width: 100%; border-collapse: collapse; margin-bottom: 15px; }
.oi_items th { border-bottom: 1px solid #ECECEC; height: 47px; background-color: #FCFCFC; padding: 0 10px; font-weight: normal; }
.oi_items td { border-bottom: 1px solid #ECECEC; padding: 15px 10px; font-size: 12px; }
.oi_items tr td:nth-child(n+1) { vertical-align: top; }
.oi_items .img { width: 60px; height: 60px; }
.oi_items .product_present { color: #FF4500; border: 1px solid #FF4500; border-radius: 2px; padding: 0 2px; }
.oi_items .title { font-size: 12px; color: #333; height: 40px; width: 600px; }
.oi_items .desc { font-size: 12px; color: #999; }
.oi_items .btn { cursor: pointer; }
.oi_items .summary .price { color: #FF4500; font-weight: bold; }

.oi_settlement { text-align: right; font-size: 14px; color: #333; }
.oi_settlement_list li { padding-bottom: 6px; }
.oi_settlement_list .label { display: inline-block; }
.oi_settlement_list .right { display: inline-block; width: 108px; }
.oi_settlement_list .right.total { font-size: 18px; color: #FA6400; font-weight: bold; }

.oi_item_buttons { text-align: center; }
.oi_item_buttons button { cursor: pointer; height: 30px; color: #FF4500; border: 1px solid #FF4500; border-radius: 2px; padding: 0 10px; font-size: 14px; }
.oi_item_buttons button.btn_solid { color: #fff; background-color: #FF4500; border: 1px solid #FF4500; }

.oi_my_service {
    width: 76px;
    height: 25px;
    cursor: pointer;
    background: url('../../../sass/images/service.png') no-repeat;
    background-size: 100%;
    display: block;
}
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; font-style: normal; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); list-style: none; box-sizing: border-box; }

body, html { @extend .ff_def; color: #333; width: 100%; height: 100%; }
html { font-size: 100px; }
body { font-size: 16px; }
a { text-decoration: none; }
input,button,textarea { -webkit-appearance: none; outline: none; box-sizing: border-box; line-height: normal; vertical-align: middle; }
input[type=button],button{ padding: 0; border: 0; border-radius: 0; }
input::-webkit-input-placeholder{ color: #999; }
button[disabled] { opacity: .5; }
textarea { resize: none; }
img:not([src]),img[src=""] { background-color: rgba(200, 200, 200, 0.5); }
button { background-color: transparent; }
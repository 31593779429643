.oi_reason_modal { width: 480px; border-radius: 2px; }
.oi_reason_modal .app-popup-container-scale { border-radius: 2px; box-shadow: 0px 12px 48px 16px rgba(0,0,0,0.03),0px 9px 28px 0px rgba(0,0,0,0.05),0px 6px 16px -8px rgba(0,0,0,0.08); background-color: #fff; }
.oi_reason { padding: 20px; }
.oi_reason_title { font-size: 16px; color: #000; padding-bottom: 10px; }
.oi_reason_icon { vertical-align: middle; width: 21px; height: 21px; border-radius: 50%; display: inline-block; margin-right: 10px; background-color: #FF4500; }
.oi_reason_icon:before, .oi_reason_icon::after { content: ''; width: 2px; height: 8px; display: block; background-color: #fff; margin-left: 50%; margin-top: 5px; position: relative; left: -1px; }
.oi_reason_icon::after { height: 2px; margin-top: 2px; }
.oi_reason_list { width: 430px; padding-top: 6px; padding-left: 10px; margin-right: -20px; }
.oi_reason_item { width: 48%; margin-right: 2%; border: 1px solid #ddd; text-align: center; margin-bottom: 6px; border-radius: 4px; padding: 6px; font-size: 14px; color: #666; cursor: pointer; }
.oi_reason_item.curr { border: 1px solid #FF4500; background-color: #FFF9E7; color: #333; }
.oi_reason_item .ftimg { width: 28px; height: 28px; }
.oi_reason_item.withSubTitle .ftimg { width: 35px; height: 35px; }
.oi_reason_btns { padding-top: 32px; }
.oi_reason_btns button { border-radius: 2px; font-size: 14px; height: 32px; width: 65px; text-align: center; border: 1px solid #ddd; margin-left: 8px; }
.oi_reason_btns button.btn_solid { background-color: #FF6F00; border: 1px solid #FF6F00; color: #fff; }

.oi_reason_refund { width: 670px; }
.oi_reason_refund .oi_reason_list { width: 630px; }
.oi_reason_refund .oi_reason_item { padding: 15px 0; }
.oi_reason_refund .oi_reason_item .title { font-size: 20px; font-weight: bold; margin-bottom: 6px; color: #000; }
.oi_reason_refund .oi_reason_item .subTitle { font-size: 14px; color: #999; }

.oi_refund_modal { width: 700px; border-radius: 2px; }
.oi_refund_modal .app-popup-container-scale { border-radius: 2px; }
.oi_refund_modal .title { padding: 10px 0 10px 20px; font-size: 18px; font-weight: bold; }

.oi_refund_item .img { width: 60px; height: 60px; float: left; display: block; }
.oi_refund_item .con { width: 500px; font-size: 12px; padding-left: 68px; }
.oi_refund_item .spec { color: #999; }
.oi_refund_item .price { color: #FF6F00; }
.oi_refund_form { margin: 10px 30px; }
.oi_refund_form select { font-size: 14px; height: 30px; border: 1px solid #ddd; border-radius: 2px; width: 200px; }
.oi_refund_form .inputText, .oi_refund_form textarea { font-size: 14px; height: 30px; border: 1px solid #ddd; border-radius: 2px; width: 200px; padding-left: 6px; }
.oi_refund_form textarea { padding: 6px; height: 80px; width: 400px; }
.oi_refund_form .logisticsFeeRefund { color: #FF6F00; display: inline-block; margin-right: 10px; vertical-align: middle; }
.oi_refund_form .desc .max { display: inline-block; margin-left: 5px; vertical-align: bottom; color: #bdbdbd; }
.oi_refund_form .desc .app-form-label { vertical-align: top; }
.oi_refund_form .pic .file { position: relative; display: inline-block; width: 50px; height: 50px; border: 2px solid #ddd; border-radius: 2px; }
.oi_refund_form .pic .file:before, .oi_refund_form .pic .file::after { position: absolute; top: 50%; left: 50%; margin-top: -1px; margin-left: -1px; width: 2px; height: 2px; content: ''; display: block; background-color: #ddd; }
.oi_refund_form .pic .file:before { width: 30px; margin-left: -15px; }
.oi_refund_form .pic .file:after { height: 30px; margin-top: -15px; }
.oi_refund_form .pic .file input { width: 100%; height: 100%; opacity: 0; }
.oi_refund_form .pic .img { width: 50px; height: 50px; display: inline-block; border-radius: 2px; margin-left: 6px; vertical-align: middle; }
.oi_refund_form .pic .img img { width: 50px; height: 50px; display: inline-block; }
.oi_refund_form .pic .img .close { width: 16px; height: 16px; display: block; z-index: 10; cursor: pointer; }
.oi_refund_form .pic .img .close:before, .oi_refund_form .pic .img .close:after { position: absolute; top: 0px; left: 7px; width: 2px; height: 16px; display: block; content: ''; background-color: #666; transform: rotate(45deg); }
.oi_refund_form .pic .img .close:before { transform: rotate(-45deg) }
.oi_refund_form .pic .note { display: inline-block; margin-left: 6px; color: #bdbdbd; font-size: 14px; }

.oi_refund_form_btns { text-align: right; padding-bottom: 20px; }
.oi_refund_form_btns button { cursor: pointer; border-radius: 2px; font-size: 14px; height: 32px; padding: 0 10px; text-align: center; border: 1px solid #ddd; margin-left: 8px; }
.oi_refund_form_btns button.btn_solid { background-color: #FF6F00; border: 1px solid #FF6F00; color: #fff; }